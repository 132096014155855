@import "@theme/vars.scss";

.link{
    display: inline-flex;
    align-items: center;
    color: $blue;
    fill: $blue-dark;
    text-decoration: none;
    transition: fill 0.25s ease, color 0.25s ease, opacity 0.25s ease;

    &:hover{
        color: $blue-dark;
        fill: $blue-dark;
    }

    span{

    }

    .icon{
        width: 24px;
        height: 24px;
    }

    .icon + span {
        margin-left: 5px;
    }

    span + .icon{
        margin-left: 5px;
    }

    &--white{
        color: #fff;
        fill: #fff;

        &:hover{
            color: $blue;
            fill: $blue;
        }
    }

    &--dark{
        color: $dark-gray;
        fill: $dark-gray;

        &:hover{
            color: $blue-dark;
            fill: $blue-dark;
        }
    }
}