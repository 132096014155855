@import '@theme/vars.scss';

.slider {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: max-content;
    position: relative; 

    &__inner{
    }   

    &__navigation{
        box-sizing: border-box;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        z-index: 2;
        padding: 0 40px;

        @media only screen and (max-width: $bp-lg) {
            padding: 0 20px;
        }
    }


    &__button {
        pointer-events: all;
        cursor: pointer;
        margin: 0;
        box-sizing: border-box;
        position: static;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        flex-shrink: 0;
        fill: $blue;
        background-color: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(6px);
        transition: background-color 0.25s ease, fill 0.25s ease, color 0.25s ease, opacity 0.25s ease;

        @media only screen and (max-width: $bp-lg) {
            width: 40px;
            height: 40px;
        }

        &:hover{
            background-color: #fff;
        }

        &::after, 
        &::before{
            content: none;
        }
    }

    &__button-icon{
        flex-shrink: 0;
        width: 24px;
        height: 24px;

        @media only screen and (max-width: $bp-lg) {
            width: 20px;
            height: 20px;
        }
    }
      
    &__pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: absolute;
        bottom: 40px !important;
        left: 0;
        z-index: 2;
        margin: 0 auto;
    }
    

    &__pagination-item {
        cursor: pointer;
        display: flex;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        flex-shrink: 0;
        margin: 0 5px;
        background-color: #A2B6C4;
        transition: background-color 0.5s ease;

        @media only screen and (max-width: $bp-md) {
            width: 12px;
            height: 12px;
            margin: 0 4px;
        }

        &.swiper-pagination-bullet-active{
            background-color: $blue;
        }
    }
}