.jsAnimate{
    opacity: 0;
    transform: translateY(-15px);
    transition: opacity 0.75s ease, transform 0.9s ease;

    &.is-animate{
        opacity: 1;
        transform: translateY(0);
    }
}
