@import '@theme/vars.scss';

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/transitions";

.fade{
	transition: opacity 0.25s linear;
}

.modal {
    --bs-modal-zindex: 100;

	&.fade &-dialog{
        transform: none !important;
	}

	&.fade &-content{
		transform: translateY(10px);
        opacity: 0;
		will-change: transform;
		transition: transform 0.35s ease-out, opacity 0.35s ease;
	}

	&.show &-content{
        opacity: 1;
		transform: none;
	}

	&-backdrop{
        --bs-backdrop-zindex: 99;
        background: rgba(4, 35, 57, 0.5);
        opacity: 0;

		&.show{
			opacity: 1;
		}
	}

	&-dialog {
		border-radius: 0;
		border: none;
		max-width: 456px;
		margin-right: auto;
		margin-left: auto;

        @media only screen and (max-width: $bp-lg) {
            padding: 0 20px;
        }
	}

	&-content {
		box-sizing: border-box;
		display: flex;
		background-color: #fff;
		border-radius: 10px;
        padding: 30px;
		border-radius: 15px;
        border: 1px solid $blue-white;
        box-shadow: 0px 12px 21px rgba(82, 117, 242, 0.04);

        @media only screen and (max-width: $bp-lg) {
            border-radius: 10px;
            padding: 24px 20px;
        }
        
        @media only screen and (max-width: $bp-md) {
            border-radius: 6px;
            padding: 20px 15px;
        }
	}

	&-close {
		width: 24px;
		height: 24px;
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		cursor: pointer;
        margin-left: auto;
        margin-bottom: 10px;

		.icon {
			width: 100%;
			height: 100%;
			transition: fill 0.25s ease;
			fill: $blue-dark;

			@media only screen and (max-width: $bp-md) {
				width: 24px;
				height: 24px;
			}
		}

		&:hover {
			.icon{
				fill: $blue-light;
			}
		}
	}

	&__main{
		display: flex;
		flex-direction: column;
	}

    &__header{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        
        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 10px;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }

        
	&__title{
        text-align: center;
        font-size: 25px;
        line-height: 125%;
        color: #000;

        @media only screen and (max-width: $bp-lg) {
            font-size: 22px;
        }

        @media only screen and (max-width: $bp-md) {
            font-size: 18px;
        }

        span{
            color: $blue;
        }
    }

	&__form{
		display: flex;
		flex-direction: column;
		flex-grow: 1;

        form{
            display: flex;
            flex-direction: column;
        }

        & > *,
        form > *{
            margin-bottom: 15px;

            @media only screen and (max-width: $bp-lg) {
                margin-bottom: 10px;
            }

            &:last-child{
                margin-bottom: 0;
            }
        }

        .input {
            max-width: none;
        }

        .button{
            margin-top: 5px;

            @media only screen and (max-width: $bp-lg) {
                margin-top: 10px;
                height: 40px;
                font-size: 14px;
            }
        
            @media only screen and (max-width: $bp-md) {
                height: 36px;
            }
        }
	}


	&--md &-dialog{
        max-width: 360px;

        @media only screen and (max-width: $bp-lg) {
            max-width: 300px;
        }

        @media only screen and (max-width: $bp-md) {
            max-width: 278px;
        }
	}
	
	&--md &-content{
		padding: 20px;
        border-radius: 15px;
	}
}