@import "@theme/vars.scss";

.personal {
  flex-grow: 1;

  .section{
    &__header{

      @media only screen and (max-width: $bp-lg) {
        display: none;
      }

      &--visible{
        @media only screen and (max-width: $bp-lg) {
          display: flex;
        }
      }
    }

    &__inner{
      padding: 0 32px;

      @media only screen and (max-width: $bp-lg) {
        padding: 0;
      }
    }
  }

  &__layout {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;

    @media only screen and (max-width: $bp-lg) {
      flex-direction: column;
    }

    &-session {
      overflow: visible;
    }
  }

  &__back-link {
    display: none;
    font-weight: 500;

    .icon{
      width: 20px;
      height: 20px;
    }

    @media only screen and (max-width: $bp-lg) {
      display: inline-flex;
      margin-bottom: 20px;
    }
  }

  &__heading {
    margin-bottom: 20px;
    padding: 0 90px 0 32px;

    @media only screen and (max-width: $bp-lg) {
      display: none;
    }
  }

  &__heading._shown-sm {
    @media only screen and (max-width: $bp-lg) {
      display: block;
      margin-bottom: 15px;
      padding: 0;
    }
  }

  &__layout-aside {
    flex-shrink: 0;
    width: 100%;
    max-width: 328px;
    margin-right: 30px;

    @media only screen and (max-width: $bp-lg) {
      order: 1;
      max-width: unset;
      padding-top: 15px;
      margin-right: 0;
    }
  }

  &__layout-aside._hidden-sm {

  }

  &__layout-main {
    box-sizing: border-box;
    flex-grow: 1;
    border-radius: 12px;
    padding: 30px;
    border: 1px solid #aebdcb;
    overflow: hidden;

    @media only screen and (max-width: $bp-lg) {
      padding: 0;
      border: 0;
      border-radius: 0;
    }

    &-session {
      overflow: visible;
    }

    &--request{
      max-width: 735px;

      @media only screen and (max-width: $bp-lg) {
        max-width: none;
      }
    }

    &--simple{
      border-radius: 0;
      padding: 0 30px;
      border: 0;

      @media only screen and (max-width: $bp-lg) {
        padding: 0;
      }
    }
  }

  &__section-title {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 15px;
  }

  &__section-title._hidden-sm {
    @media only screen and (max-width: $bp-lg) {
      display: none;
    }
  }

  &__user-bio {
    padding-top: 15px;

    @media only screen and (max-width: $bp-lg) {
      padding-top: 0;
    }
  }
}


.personal-main {


  &__header {
    margin-bottom: 25px;

    &:last-child{
      margin-bottom: 0;
    }

    &>*:last-child{
      margin-bottom: 0;
    }
  }

  &__title{
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #1D3A49;
    margin-bottom: 15px;
    font-family: "CenturyGothic", 'HelveticaNeue', sans-serif;
  }

  &__text{
    color: $dark;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 25px;
  }

  &__body {
  }

  &__form {
    margin-bottom: 25px;

    &:last-child{
      margin-bottom: 0;
    }
  }

  &__table {
    width: 100%;
    padding-top: 25px;
    border-top: 3px solid $blue;
    margin-top: 20px;
  }

  &__table-content{
    width: 100%;
    overflow: auto hidden;
  }

  &__table-title{
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $dark;
    margin-bottom: 25px;

    @media only screen and (max-width: $bp-lg) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__table-text{
    color: $dark;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;

    @media only screen and (max-width: $bp-lg) {
      font-size: 14px;
      line-height: 17px;
    }

    span{
      font-weight: 500;
    }

    &:first-child{
      margin-top: 0;
    }
  }

  &__table-row{
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    &:first-child{
      margin-top: 0;
    }
  }
}

.personal-form {

  &__block {
    margin-bottom: 30px;

    @media only screen and (max-width: $bp-lg) {
      margin-bottom: 25px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    &--request{
      max-width: 675px;

      @media only screen and (max-width: $bp-lg) {
        max-width: none;
      }
    }
  }

  &__block--request &__group-title{
    font-weight: 400;
    min-width: 160px;

    @media only screen and (max-width: $bp-lg) {
      font-size: 14px;
    }
  }

  &__block--request &__group-list{

    @media only screen and (max-width: $bp-lg) {
      margin-top: 10px;
    }

    .input{
      width: calc(100% - 15px);
      min-width: auto;

      @media only screen and (max-width: $bp-lg) {
        width: calc(100% - 10px);
      }
    }

    .select{
      height: 42px;
    }
  }

  &__block-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $dark;
    margin-bottom: 20px;

    @media only screen and (max-width: $bp-lg) {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 15px;
    }
  }

  &__text {
  }

  &__group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -15px;
    margin-bottom: 20px;

    &:last-child{
      margin-bottom: 0;
    }

    @media only screen and (max-width: $bp-lg) {
      flex-direction: column;
      align-items: initial;
      margin-bottom: 15px;
    }

    &--top{
      align-items: flex-start;
    }
  }

  &__group-title {
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $dark;
    padding-right: 30px;
    margin-top: 15px;
  }

  &__group-list {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    margin: 15px -7.5px -15px;

    @media only screen and (max-width: $bp-lg) {
      margin-right: -5px;
      margin-left: -5px;
      margin-bottom: -10px;
      min-width: calc(100% + 10px);
    }

    .button {
      height: 47px;
    }

    &>*{
      margin: 0 7.5px 15px;

      @media only screen and (max-width: $bp-lg) {
        margin-right: 5px;
        margin-left: 5px;
        margin-bottom: 10px;
      }
    }

    &-ses {
      flex-wrap: nowrap;

      select {
        width: 100%;
      }

      @media only screen and (max-width: $bp-lg) {
        flex-wrap: wrap;
      }
    }

    .badge{
      height: 36px;

      @media only screen and (max-width: $bp-lg) {
        height: 42px;
      }
    }

    .select{
      min-width: 250px;

      @media only screen and (max-width: $bp-lg) {
        min-width: 180px;
      }

      @media only screen and (max-width: $bp-md) {
        width: calc(100% - 10px);
      }
    }

    .radio{

      @media only screen and (max-width: $bp-md) {
        min-width: calc(50% - 10px);
      }
    }

    .checkbox{
      min-width: calc(50% - 15px);
      flex-grow: 1;

      @media only screen and (max-width: $bp-lg) {
        width: calc(100% - 10px);
        min-width: auto;
      }
    }

    .button{
      @media only screen and (max-width: $bp-md) {
        width: calc(100% - 10px);
      }
    }

    .input{
      min-width: calc(50% - 15px);
      width: auto;
      flex-grow: 1;
      max-width: none;

      @media only screen and (max-width: $bp-lg) {
        min-width: auto;
        width: calc(100% - 10px);
      }

      &__field:is(textarea) {
      }

      &__field-textarea {
        overflow: hidden;
        resize: none;

        &:valid {
          height: auto;
        }
      }
    }
  }

  &__submit{
    margin-left: auto;
    min-width: calc(50% - 15px);
  }
}

.table {
  font-size: 18px;
  line-height: 21px;
  color: $dark;
  width: max-content;
  min-width: 100%;

  @media only screen and (max-width: $bp-xl) {
    font-size: 16px;
  }

  @media only screen and (max-width: $bp-lg) {
    font-size: 14px;
  }

  @media only screen and (max-width: $bp-md) {
    font-size: 12px;
  }


  thead {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;

    @media only screen and (max-width: $bp-xl) {
      font-size: 14px;
    }

    @media only screen and (max-width: $bp-lg) {
      font-size: 12px;
    }
  }

  tbody {
    &:first-child tr:first-child {
      margin-top: -10px;
    }
  }

  tr:last-child td {
    border-bottom: 0;
    padding-bottom: 0;
  }

  td,
  th {
    box-sizing: border-box;
    padding: 10px;
    border-bottom: 1px solid rgba(175, 190, 203, 0.4);
    text-align: center;
    max-width: 200px;

    @media only screen and (max-width: $bp-xl) {
      min-width: 160px;
      max-width: 240px;
    }

    @media only screen and (max-width: $bp-lg) {
      padding: 10px 5px 5px;
      min-width: 120px;
      max-width: 180px;
      text-align: left;
    }

    @media only screen and (max-width: $bp-md) {
      min-width: 80px;
      max-width: 120px;
    }

    &:last-child {
      text-align: right;
      padding-right: 0;
    }

    &:first-child {
      padding-left: 0;
      text-align: left;
    }

  }

  th {
    border-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
    vertical-align: top;
  }

  .link {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-left: 30px;

    @media only screen and (max-width: $bp-lg) {
      font-size: 14px;
      margin-left: 20px;
    }

    @media only screen and (max-width: $bp-md) {
      font-size: 12px;
    }

    &:first-child {
      margin-left: 0;
    }

    .icon {
      @media only screen and (max-width: $bp-lg) {
        width: 20px;
        height: 20px;
      }
    }
  }


  &--calls {
    display: block;

    @media only screen and (max-width: $bp-md) {
      font-size: 14px;
    }

    tbody {
      display: block;

      &:first-child tr:first-child {
        @media only screen and (max-width: $bp-lg) {
          margin-top: -25px;
        }
      }
    }

    tr {
      display: flex;
      align-items: center;

      @media only screen and (max-width: $bp-lg) {
        display: flex;
        flex-direction: column;
        align-items: initial;
      }
    }

    td {
      display: flex;
      align-items: center;
      flex-grow: 1;
      max-width: none;
      justify-content: center;

      &:last-child {
        justify-content: flex-end;
      }

      &:first-child {
        justify-content: flex-start;

        @media only screen and (max-width: $bp-lg) {
          font-size: 16px;
          padding-bottom: 5px;
          padding-top: 25px;
        }
      }

      @media only screen and (max-width: $bp-lg) {
        justify-content: flex-start !important;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 10px;

        &:first-child {
          border-bottom: 0;
        }
      }

    }
  }
}

