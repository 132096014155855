@import "@theme/vars.scss";

.s-additionals {
    padding-bottom: 30px;

    @media only screen and (max-width: $bp-lg) {
        padding-bottom: 60px;
    }

    &__header {
        margin-right: 50px;
        max-width: 280px;

        @media only screen and (max-width: $bp-xl) {
            margin-bottom: 40px;
            margin-right: 0;
            max-width: none;
        }

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 30px;
        }
    }

    &__title {
        color: $dark-gray;
    }

    &__inner {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media only screen and (max-width: $bp-xl) {
            flex-direction: column;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px -20px;

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: -15px;
        }

        @media only screen and (max-width: $bp-md) {
            margin-bottom: -10px;
        }
    }

    &__card{
        width: calc(50% - 10px);
        margin: 0 5px 20px;

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 15px;
        }
        
        @media only screen and (max-width: $bp-md) {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}
