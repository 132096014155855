@import "@theme/vars.scss";

.aside-nav {

  &__link {

    .badge__wrapper {
      background: #FFFFFF;
      color: $blue-dark;
      border: 1px solid $blue-dark;
      transition: background-color .3s ease;

      &:hover {
        background-color: rgba(0, 82, 142, 0.2);
      }
    }
  }

  .is-active {
    .badge__wrapper {
      background: $blue;
      color: #FFFFFF;
      border: 1px solid $blue;
      box-shadow: 0 12px 21px rgba(82, 117, 242, 0.58);

      &:hover {
        background-color: $blue-dark;
        border: 1px solid $blue-dark;
        color: #FFFFFF;
      }
    }
  }

  &__link:not(:last-child) {
    margin-bottom: 15px;

    @media only screen and (max-width: $bp-lg) {
      margin-bottom: 10px;
    }
  }

  &__link:last-child {
    margin-top: 60px;

    @media only screen and (max-width: $bp-lg) {
      margin-top: 40px;
    }
  }

  &__link .badge__wrapper {
    justify-content: flex-start;

    @media only screen and (max-width: $bp-lg) {
      background-color: $blue;
      color: #FFFFFF;
    }

  }

  &__link .badge__wrapper &__link-icon {
    display: none;
    width: 14px;
    height: 14px;
    margin-left: auto;

    @media only screen and (max-width: $bp-lg) {
      display: block;
    }
  }
}
