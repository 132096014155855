@import "@theme/vars.scss";

.icon{
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    &--lg{
        width: 32px;
        height: 32px;
    }

    &--md{
        width: 16px;
        height: 16px;
    }
}