@import "@theme/vars.scss";

html {
  font-family: $defaultFontFamily;
  font-weight: 400;
  font-size: $defaultFontSize;
  line-height: $defaultLineHeight;
  color: $dark;
  background-color: #FAFAFA;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;

    &._lock {
        overflow: hidden;
    }
}

*{
    -webkit-tap-highlight-color: transparent;
    scrollbar-color: $blue #DEE5E8;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: #DEE5E8;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $blue;
        border-radius: 2px;
    }

    &::selection{
        color: #fff;
        background-color: rgba(137, 148, 233, 0.85);
    }
}

img{
  image-rendering: -webkit-optimize-contrast;
}

.main{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    // .breadcrumbs + section{
    //     padding-top: 0;
    // }

    section,
    article {
        padding-bottom: 30px;

        @media only screen and (max-width: $bp-lg) {
            padding-bottom: 45px;
        }

        @media only screen and (max-width: $bp-md) {
            padding-bottom: 60px;
        }
        // overflow: hidden;

        // &:first-of-type{
        //     padding-top: 0;
        // }

        // &:last-child{
        //     padding-bottom: 50px;
        // }
    }
}

.section{
    box-sizing: border-box;
    padding: 15px 0 30px;

    &--visible{
        overflow-x: visible;
    }

    &__header{
        padding: 0 32px;
        margin-bottom: 15px;

        @media only screen and (max-width: $bp-lg) {
            padding: 0;
        }

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 20px;
        }
    }

    &__title{
        color: #003042;
    }

    &__subtitle {
        margin-top: 10px;
        font-size: 16px;
        line-height: 22px;

        /* unactive */

        color: #7D8C99;
    }

    &__badges{
        box-sizing: border-box;
        margin-top: 20px;

        margin-right: -72px;
        padding-left: 72px;
        margin-left: -72px;
        padding-right: 72px;

        @media only screen and (max-width: $bp-xl) {
            margin-right: -40px;
            padding-left: 40px;
            margin-left: -40px;
            padding-right: 40px;
        }

        @media only screen and (max-width: $bp-lg) {
            margin-top: 15px;
            margin-right: -25px;
            padding-left: 25px;
            margin-left: -25px;
            padding-right: 25px;
        }

        @media only screen and (max-width: $bp-md) {
            margin-right: -20px;
            padding-left: 20px;
            margin-left: -20px;
            padding-right: 20px;
        }


        -webkit-tap-highlight-color: transparent;
        scrollbar-color: transparent transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
    }

    &__inner{
        position: relative;
        z-index: 1;
    }
}


.container {
    box-sizing: border-box;
    width: 100%;
    max-width: 1288px;
    padding: 0 20px;
    margin: 0 auto;

  &_m {
    max-width: 1440px;
    padding: 0 40px;
  }
}
