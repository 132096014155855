@import "@theme/vars.scss";

.s-constructor {
    padding-bottom: 30px;

    &__inner {
        box-sizing: border-box;
        flex-grow: 1;
        padding: 34px 55px 60px;
        background-color: $blue-dark;
        border: 2px solid $blue-white;
        border-radius: 15px;

        @media only screen and (max-width: $bp-xl) {
            padding: 32px 44px 0;
        }

        @media only screen and (max-width: $bp-lg) {
            padding: 32px 32px 0;
            border: 0;
        }

        @media only screen and (max-width: $bp-md) {
            padding: 32px 20px 0;
        }

        form {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin: 0;

          @media only screen and (max-width: $bp-lg) {
            flex-direction: column;
          }
        }
    }

    &__main {
      @media only screen and (min-width: $bp-lg + 1) {
        max-width: calc(100% - 456px);
      }
    }

    &__result .constructor-result__header {
      @media only screen and (max-width: $bp-lg) {
        opacity: 0;
        display: none;
      }
    }

    &__result._shown .constructor-result__header {
      @media only screen and (max-width: $bp-lg) {
        opacity: 1;
        display: block;
        animation: fadeIn 0.5s;
      }
    }

    &__result .constructor-result__body {
      @media only screen and (max-width: $bp-lg) {
        opacity: 0;
        display: none;
      }
    }

    &__result._shown .constructor-result__body {
      @media only screen and (max-width: $bp-lg) {
        opacity: 1;
        display: block;
        animation: fadeIn 0.5s;
      }
    }

    &__result .constructor-result__footer {
      @media only screen and (max-width: $bp-lg) {
        margin: 0;
      }
    }


    @media only screen and (min-width: $bp-lg + 1) {
        &--separated &__inner{
            padding: 0;
            background-color: transparent;
            border: 0;
        }

        &--separated &__main{
            position: relative;
            background-color: $blue-dark;
            border: 2px solid $blue-white;
            border-radius: 15px;
            padding: 34px 100px 60px 54px;

            @media only screen and (max-width: $bp-xl) {
                padding: 32px 44px 52px;
            }
            

            &::before{
                content: "";
                display: block;
                position: absolute;
                right: 18px;
                bottom: 60px;
                width: 136px;
                height: 248px;
                background-image: url("data:image/svg+xml,%3Csvg width='137' height='250' viewBox='0 0 137 250' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M85.6028 26.4906C85.6028 40.6054 97.0711 52.0736 111.186 52.0736C125.301 52.0736 136.769 40.6054 136.769 26.4906C136.769 12.3757 125.301 0.907471 111.186 0.907471C97.3652 0.907471 85.6028 12.3757 85.6028 26.4906Z' fill='%230A5C98'/%3E%3Cpath d='M86.4842 88.8311V224.098C86.4842 237.919 97.6584 248.799 111.185 248.799C125.006 248.799 135.886 237.625 135.886 224.098V88.8311C135.886 75.0103 124.712 64.1301 111.185 64.1301C97.6584 64.1301 86.4842 75.3044 86.4842 88.8311Z' fill='%230A5C98'/%3E%3Cpath d='M0.325195 28.8426C0.325195 35.9 6.20637 41.7812 13.2638 41.7812C20.3212 41.7812 26.2023 35.9 26.2023 28.8426C26.2023 21.7852 20.3212 15.9041 13.2638 15.9041C6.20637 15.9041 0.325195 21.4912 0.325195 28.8426Z' fill='%230A5C98'/%3E%3Cpath d='M0.913328 63.8357V236.742C0.913328 243.505 6.50044 249.093 13.2638 249.093C20.0271 249.093 25.6142 243.505 25.6142 236.742V63.8357C25.6142 57.0724 20.0271 51.4853 13.2638 51.4853C6.50044 51.4853 0.913328 57.0724 0.913328 63.8357Z' fill='%230A5C98'/%3E%3Cpath d='M38.5522 64.1298C38.5522 73.5397 46.1977 81.4793 55.6076 81.4793C65.0175 81.4793 72.663 73.8338 72.663 64.1298C72.663 54.72 65.0175 47.0744 55.6076 47.0744C46.4918 46.7804 38.5522 54.4259 38.5522 64.1298Z' fill='%230A5C98'/%3E%3Cpath d='M39.4352 109.121V232.625C39.4352 241.741 46.7867 249.093 55.9025 249.093C65.0183 249.093 72.3698 241.741 72.3698 232.625V109.121C72.3698 100.005 65.0183 92.6534 55.9025 92.6534C46.7867 92.3594 39.4352 99.7108 39.4352 109.121Z' fill='%230A5C98'/%3E%3C/svg%3E%0A");
            }
        }

        &--separated &__result{
            padding: 40px 50px;
            margin-left: 10px;
        }
    }
}

.constructor {
    color: #fff;
    flex-grow: 1;
    width: 100%;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 42px;

        @media only screen and (max-width: $bp-xl) {
            margin-bottom: 32px;
        }

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 20px;
        }
    }

    &__title {
    }

    &__trigger {
        position: relative;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        flex-shrink: 0;
        fill: #fff;
        background-color: $blue-dark;
        box-shadow: 0px 14px 24px rgba(82, 117, 242, 0.28);
        display: none;
        transition: background-color 0.25s ease, fill 0.25s ease;
        
        @media only screen and (max-width: $bp-lg) {
            display: flex;
            position: absolute;
            right: 14px;
            top: 12px;
            width: 24px;
            height: 24px;
        }

        &:hover{
            background-color: $blue-medium;
            fill: $blue;
        }

        .icon{
            width: 24px;
            height: 24px;
            flex-shrink: 0;

            @media only screen and (max-width: $bp-lg) {
              width: 16px;
              height: 16px;
          }
        }

        span{
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 18px;
            height: 18px;
            border-radius: 10px;
            padding: 0 4px;
            background-color: #00A6E0;
            font-size: 9px;
            line-height: 145%;
            color: #fff;
            position: absolute;
            top: 0;
            left: 100%;
            transform: translateX(-62%);
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4px 10px;

        @media only screen and (max-width: $bp-md) {
            flex-direction: column;
        }

        &:last-child{
            margin-bottom: -15px;
        }
    }

    &__prop {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 0 4px;
        margin: 0 0 15px;
        flex: 1 0 50%;
        max-width: 100%;
    }

    &__prop-header {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    &__prop-title {
        display: flex;
        align-items: center;

        img{
            display: block;
            width: 25px;
            height: 25px;
            flex-shrink: 0;
            margin: 2px 5px auto 0;

            @media only screen and (max-width: $bp-lg) {
                margin-top: 0;
            }
        }

        h3{
            font-weight: 400;
            font-size: 20px;
            line-height: 145%;
            
            @media only screen and (max-width: $bp-lg) {
                font-size: 18px;
            }

            @media only screen and (max-width: $bp-md) {
                font-size: 16px;
            }
        }
    }

    &__prop-subtitle {
        font-weight: 300;
        color: #CCDCE8;
        margin-top: 5px;
    }

    &__prop-body {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -20px;

        @media only screen and (max-width: $bp-md) {
            flex-direction: column;
            align-items: initial;
            margin: 0;
        }

        &:last-child{
            margin-bottom: -15px;

            @media only screen and (max-width: $bp-md) {
                margin-bottom: -20px;
            }
        }
        
        .range{
            flex: 0 1 50%;
            min-width: 250px;
            margin-bottom: 32px;

            @media only screen and (max-width: $bp-md) {
                flex-basis: 100%;
                min-width: auto;
            }
        }

        .range--storage .noUi-value[data-value="0"] {
          font-size: 0;

          &::after {
            content: '0';
            font-size: 14px;
            line-height: 20px;
          }
        }

        .select{
            flex: 0 1 50%;
            min-width: 250px;

            @media only screen and (max-width: $bp-md) {
                flex: unset;
                min-width: auto;
                height: 34px;
            }
        }

        .input {
          flex: 0 1 50%;

          @media only screen and (max-width: $bp-md) {
            width: 100%;
          }
        }

        &>*{
            margin: 0 20px 15px;

            @media only screen and (max-width: $bp-md) {
                margin: 0 0 20px;
            }
        }

        .badges:not(.badges--group) .badges{
            &__list{
                @media only screen and (max-width: $bp-md) {
                    flex-direction: column;
                }
            }
        }
    }

    &__prop-body._included {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }

    &__prop-list {
      margin: 0 0 20px;
    }

    &__prop-list._storages {
      width: 430px;
    }

    &__prop-info {
        box-sizing: border-box;
        width: 50%;
        padding: 15px;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 5px;

        @media only screen and (max-width: $bp-lg) {
            width: 100%;
        }

        p{
            font-weight: 400;
            font-size: 16px;
            line-height: 145%;
            margin-bottom: 10px;

            @media only screen and (max-width: $bp-lg) {
                font-size: 14px;
            }

            @media only screen and (max-width: $bp-md) {
                font-size: 12px;
            }

            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    .badge__wrapper {
      border-color: #fff;
    }
    
    .badge__wrapper .badge__input:checked + &__wrapper,
    .badge.is-active &__wrapper {
      background-color: #fff;
    }
}

.constructor-result {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid $blue-white;
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0px 12px 21px rgba(82, 117, 242, 0.04);
    transition: transform 0.75s cubic-bezier(.65,.05,.41,.95);

    @media only screen and (max-width: $bp-lg) {
        padding: 24px;
        border-radius: 10px;
        margin-top: 32px;
    }

    @media only screen and (max-width: $bp-md) {
        padding: 15px 14px;
    }

    &.is-open{
        transform: translateX(0);
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }

    &__close {
        cursor: pointer;
        display: none;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        flex-shrink: 0;
        border-radius: 50%;
        margin-right: 14px;
        fill: $blue-dark;
        background-color: $blue-white;
        transition: background-color 0.25s ease;

        &:hover{
            background-color: $blue-medium;
        }

        @media only screen and (max-width: $bp-lg) {
            display: flex;
        }

        .icon{
            width: 20px;
            height: 20px;
        }
    }

    &__title {
        font-weight: 700;
        color: #595959;

        @media only screen and (max-width: $bp-lg) {
            font-size: 22px;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: $bp-lg) {
            overflow: hidden auto;
        }

        &>*:last-child{
            margin-bottom: 0;
        }

        &::-webkit-scrollbar {
            height: 4px;
            width: 4px;
        }
    }

    &__props {
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 14px;
        }
    }

    &__prop {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        &:last-child{
            margin-bottom: 0;
        }
        
        img{
            width: 25px;
            height: 25px;
            flex-shrink: 0;
            margin: 2px 10px auto 0;

            @media only screen and (max-width: $bp-lg) {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }

            @media only screen and (max-width: $bp-md) {
                width: 16px;
                height: 16px;   
                margin: 1px 6px auto 0;
            }
        }

        p{
            font-size: 16px;
            line-height: 145%;
            color: #595959;

            @media only screen and (max-width: $bp-md) {
                font-size: 14px;
            }
        }
    }

    &__month {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 135%;
        color: #595959;
        margin-bottom: 20px;

        @media only screen and (max-width: $bp-lg) {
            font-size: 14px;    
            margin-bottom: 14px;
        }

        @media only screen and (max-width: $bp-md) {
            flex-direction: column;
            align-items: flex-start;
        }

        span{
            font-size: 18px;
            color: $blue-dark;
            margin-left: 14px;  

            @media only screen and (max-width: $bp-lg) {
                font-size: 16px;
            }

            @media only screen and (max-width: $bp-md) {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }

    &__period {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;
        color: #595959;
        font-size: 16px;
        line-height: 135%;

        @media only screen and (max-width: $bp-lg) {
            font-size: 14px;
            margin-bottom: 14px;
            justify-content: flex-start;
        }

        @media only screen and (max-width: $bp-md) {
            flex-direction: column;
            align-items: flex-start;
        }

        .select{
            width: 192px;
            margin-left: 10px;

            @media only screen and (max-width: $bp-lg) {
                margin-left: 40px;
            }

            @media only screen and (max-width: $bp-md) {
                width: 100%;
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        @media only screen and (max-width: $bp-lg) {
            margin-top: 14px;
        }
    }

    &__total {
        font-weight: 700;
        font-size: 25px;
        line-height: 145%;
        color: $blue-dark;
        margin-bottom: 20px;
        
        @media only screen and (max-width: $bp-lg) {
            font-size: 20px;
            margin-bottom: 14px;
        }

        @media only screen and (max-width: $bp-md) {
            font-size: 17px;
        }
    }

    &__submit {
    }
}

.s-constructor__result  {
    position: -webkit-sticky;
    position: sticky;
    width: 456px;
    flex-shrink: 0;
    margin-left: 65px;

    @media only screen and (max-width: $bp-xl) {
        margin-left: 0;
    }

    @media only screen and (max-width: $bp-lg) {
      bottom: 0;
      z-index: 10;
      width: calc(100% + 2px);
      margin: 32px -1px;
      border: 0;
      box-shadow: none;
    }

    @media only screen and (min-width: $bp-lg + 1) {
      top: 130px;
      padding: 40px;
      max-height: calc(100vh - 130px);
      overflow: auto;
    }
}

.constructor__row._storages {
  .constructor__prop-list {
    width: 100%;
  }

  .badge {
    flex-shrink: 0;
  }

  .range {
    flex-grow: 1;
  }

  .range--scale .noUi-base::after {
    padding: 14px 40px 36px;
    left: 50%;
    transform: translate(-50%, -14px);
  }
}
