@import "@theme/vars.scss";

.s-services {
    padding-bottom: 30px;

    @media only screen and (max-width: $bp-lg) {
        padding-bottom: 60px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        &>*{
            margin-bottom: 20px;
        }

        @media only screen and (max-width: $bp-md) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__title {
        color: $dark-gray;
        margin-right: 20px;

        @media only screen and (max-width: $bp-lg) {
            margin-right: 0;
        }
    }

    &__links{
        display: flex;
        align-items: center;
        margin-right: -15px;
        margin-left: -15px;

        @media only screen and (max-width: $bp-lg) {
            margin-right: -10px;
            margin-left: -10px;
            flex-wrap: wrap;
        }

        @media only screen and (max-width: $bp-md) {
            margin-right: -8px;
            margin-left: -8px;
        }
    }

    &__link{
        font-weight: 300;
        font-size: 20px;
        line-height: 145%;
        margin: 0 15px;
        transition: color 0.25s ease, fill 0.25s ease, text-shadow 0.25s ease;

        @media only screen and (max-width: $bp-lg) {
            font-size: 18px;
            margin: 0 10px;
        }

        @media only screen and (max-width: $bp-md) {
            font-size: 16px;
            margin: 0 8px;
        }

        &.is-active{
            pointer-events: none;
            color: $blue-dark;
            fill: $blue-dark;
            text-shadow: $blue-dark 0px 0 0.75px;
        }
    }

    &__inner {
     
    }

    &__tab{
        display: none;

        &.is-active{
            display: flex;
            flex-direction: column;
            animation: fadeInTop 0.6s ease;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px -20px;
        flex-grow: 1;

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: -15px;
        }

        @media only screen and (max-width: $bp-sm) {
            flex-direction: column;
            margin-right: 0;
            margin-left: 0;
        }
    }

    &__card{
        width: calc(33.33% - 10px);
        margin: 0 5px 20px;

        @media only screen and (max-width: $bp-xl) {
            width: calc(50% - 10px);
        }

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 15px;
        }
        
        @media only screen and (max-width: $bp-sm) {
            width: 100%;
            margin-right: 0;
            margin-left: 0;
        }
    }
}
