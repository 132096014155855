@import "@theme/vars.scss";

.c-advantage {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E8EDFD;
    color: $dark;
    border-radius: 10px;
    padding: 35px 25px 22px;
    color: $dark;

    @media only screen and (max-width: $bp-lg) {
        padding: 30px 20px 20px;
    }

    @media only screen and (max-width: $bp-md) {
        border-radius: 7px;
        padding: 15px;
        flex-direction: row;
        justify-content: flex-start;
    }

    img {
        display: block;
        width: 55px;
        height: 55px;
        flex-shrink: 0;
        margin-bottom: 16px;
        border-radius: 50%;
        box-shadow: 0px 12px 21px rgba(82, 117, 242, 0.28);

        @media only screen and (max-width: $bp-md) {
            width: 38px;
            height: 38px;
            margin: 4px 15px 4px 0;
        }
    }

    p{
        font-size: 18px;
        line-height: 135%;

        @media only screen and (max-width: $bp-lg) {
            font-size: 16px;
        }

        @media only screen and (max-width: $bp-md) {
            font-size: 14px;
            line-height: 145%;
        }
    }
}
