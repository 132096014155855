@import "@theme/vars.scss";

.promo-item {
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: rgba(2, 12, 23, 0.2);
  }

  &__img {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__title, &__text {
    color: $white;
    position: relative;
    z-index: 3;
  }

  &__title {
    font-size: 22px;
    font-weight: 700;
    line-height: 25px;

    @media only screen and (max-width: $bp-md) {
      font-size: 18px;
      line-height: 21px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 157%;
    margin-top: 8px;

    @media only screen and (max-width: $bp-md) {
      font-size: 12px;
      line-height: 19px;
    }
  }

  &_profit {

    &:after {
      content: 'Выгодно';
      background: $green;
    }
  }

  &_ends {

    &:after {
      content: 'Скоро закончится';
      background: $red-light;
    }
  }

  &_profit:after, &_ends:after {
    box-sizing: border-box;
    display: block;
    padding: 4px 8px;
    border-radius: 5px;
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: $white;
    top: 20px;
    right: 20px;
    position: absolute;
    z-index: 4;

    @media only screen and (max-width: $bp-md) {
      font-size: 12px;
      line-height: 17px;
    }
  }
}
