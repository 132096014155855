@import "@theme/vars.scss";

.header {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    right: 0;
    z-index: 98;
    width: 100%;
    transition: background-color 0.5s ease;
    background-color: #fff;
    margin-bottom: 3px;

    &__container {
        z-index: 98;

        @media only screen and (max-width: $bp-lg) {
            background-color: #fff;
        }
    }

    &__inner {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 10px 0;
        width: 100%;
        z-index: 2;
        position: relative;

        @media only screen and (max-width: $bp-lg) {
            height: 58px;
        }
    }

    &__burger {
        cursor: pointer;
        box-sizing: border-box;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        margin-right: 15px;
        display: flex;

        &::before,
        &::after,
        span {
            content: "";
            width: 32px;
            height: 3px;
            background-color: $blue-dark;
            position: absolute;
            left: 50%;
            top: 50%;
            transform-origin: center;
            transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.4s ease, background-color 0.25s ease;
        }

        span{
            opacity: 1;
            transform: translate(-50%, -50%);
        }

        &::before{
            transform: translate(-50%, calc(-50% - 8px));
        }

        &::after{
            transform: translate(-50%, calc(-50% + 8px));
        }

        &:hover{
            &::before,
            &::after,
            span{
                background-color: $blue;
            }
        }

        &.is-active{
            position: absolute;
            left: 370px;
            top: 100px;

            @media only screen and (max-width: $bp-lg) {
              position: relative;
              left: auto;
              top: auto;
            }

            &:before{
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &:after{
                transform: translate(-50%, -50%) rotate(45deg);
            }

            span{
                transform: translate(-50%, -50%);
                opacity: 0;
            }
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        width: 102px;
        height: 45px;

        @media only screen and (max-width: $bp-lg) {
            width: 95px;
            height: 38px;
            margin-right: auto;
        }
    }

    &__contacts {
        display: flex;
        align-items: center;
        margin-right: 0;
        margin-left: auto;

        @media only screen and (max-width: $bp-lg) {
            display: none;
        }
    }

    &__contacts-item {
        display: flex;
        align-items: center;
        margin: 0 20px;
        font-size: 14px;
        line-height: 135%;

        @media only screen and (max-width: $bp-lg) {
            flex-direction: column;
            align-items: flex-start;
            margin: 0 0 15px;

            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    &__contacts-title {
        color: #8C8C8C;
        margin-right: 5px;

        @media only screen and (max-width: $bp-lg) {
            margin: 0 0 10px;
        }
    }

    &__contacts-content {
        display: flex;
        align-items: center;

        &>*{
            margin-right: 5px;

            @media only screen and (max-width: $bp-lg) {
                margin-right: 10px;
            }

            &:last-child{
                margin-right: 0;
            }
        }

        a{
            display: inline-flex;
            align-items: center;
            color: $dark-gray;
            fill: $blue-dark;
            text-decoration: none;
            transition: fill 0.25s ease, color 0.25s ease;

            &:hover{
                color: $blue;
                fill: $blue;
            }

            .icon{
                width: 20px;
                height: 20px;

                @media only screen and (max-width: $bp-lg) {
                    width: 35px;
                    height: 35px;
                }
            }

            .icon + span {
                margin-left: 5px;
            }

            span + .icon{
                margin-left: 5px;
            }
        }
    }

    &__callback {
        display: none;
        margin-right: 30px;
        width: 24px;
        height: 24px;
        order: 1;

        .icon{
            width: 20px;
            height: 20px;
        }

        @media only screen and (max-width: $bp-lg) {
            display: flex;
        }
    }

    &__login {
        font-size: 16px;
        line-height: 135%;
        order: 1;
        margin-right: 0;

        @media only screen and (max-width: $bp-lg) {
            span{
                display: none;
            }

            .icon{
                margin-left: 0 !important;
            }
        }
    }

    &__dropdown {
        box-sizing: border-box;
        border-top: 1px solid #F0F0F0;
        pointer-events: none;
        background-color: #fff;
        padding: 0 0 30px;
        border-top: 5px solid #FAFAFA;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 395px;
        height: 100vh;
        z-index: 99;
        transform: translateX(-100%);
        transition: transform 0.65s ease, box-shadow 0.25s ease;
        transition-delay: 0s, 0.4s;

        @media only screen and (max-width: $bp-md) {
          width: 100%;
          height: 100vh;
        }

        &.is-open{
          pointer-events: auto;
          opacity: 1;
          box-shadow: 26px 4px 100px rgba(0, 0, 0, 0.25);
          transform: translateX(0%);
          transition-delay: 0s, 0.2s, 0.2s;
          transition: transform 0.65s ease, box-shadow 0.25s ease;
        }
    }

  &__dropdown-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(162, 182, 196, 0.5);
    padding: 12px 50px 12px 40px;
    align-items: center;

    @media only screen and (max-width: $bp-lg) {
      align-items: center;
      padding: 12px 40px;
    }
  }

  &__dropdown.is-open &__dropdown-logo{
    transform: translateX(0%);
    opacity: 1;
    transition-delay: 0.2s;
  }

  &__dropdown.is-open &__dropdown-close{
    transform: translateX(0%);
    opacity: 1;
    transition-delay:0s, 0.2s, 0.2s;
  }

  &__dropdown.is-open &__dropdown-inner{
    transform: translateX(0%);
    transition-delay: 0.35s;
  }

  &__dropdown-logo {
    width: 122px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-150px);
    transition: transform 0.65s ease, opacity 0.65s ease;

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media only screen and (max-width: $bp-md) {
      width: 104px;
      height: 50px;
    }
  }

    &__dropdown-inner {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-height: 100%;
        margin-top: 30px;
        transition: transform 0.65s ease, opacity 0.5s ease;
        transform: translateX(-150px);
    }

    &__nav {

    }

    &__button{
        height: 42px;
        padding-right: 22px;
        padding-left: 22px;

        .button__icon{
            transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }

        &.is-active .button__icon{
            transform: rotate(180deg);
        }

        &.is-active{
            border-color: transparent;
            box-shadow: none;
        }

        @media only screen and (max-width: $bp-lg) {
            display: none;
        }
    }

    &__contacts-wrapper{
        position: relative;
        order: 2;
    }

    @media only screen and (min-width: $bp-lg + 1) {
        &--two &__button,
        &--three &__button {
            order: 3;
        }

        &--two &__login,
        &--three &__login {
            display: none;
        }

        &--two &__nav,
        &--three &__nav {
            margin-left: auto;
            margin-right: auto;
            order: 1;
        }

        &--two &__dropdown,
        &--three &__dropdown{
            display: none;
        }

        &--three &__contacts{
            flex-direction: column-reverse;
            align-items: initial;
            width: 350px;
            position: absolute;
            top: 100%;
            right: 0;
            padding: 10px;
            border-radius: 5px 0px 5px 5px;
            background-color: #fff;
            visibility: hidden;
            opacity: 0;
            transform: translateY(-10px);
            transition: max-height 0.5s ease, opacity 0.25s ease, visibility 0.25s ease, transform 0.4s ease;

            &.is-open{
                transform: translateY(0px);
                opacity: 1;
                visibility: visible;
            }
        }

        &--three &__contacts-title{
            flex-basis: 50%;
        }

        &--three &__contacts-item{
            margin: 0 0 10px;

            &:first-child{
                margin-bottom: 0;
            }
        }


        &--two &__contacts-wrapper &__button{
            display: none;

            @media only screen and (max-width: $bp-xl) {
                display: flex;
            }
        }

        @media only screen and (max-width: $bp-xl) {

            &--two &__contacts-wrapper{
                margin-right: 20px;
            }

            &--two &__contacts{
                flex-direction: column-reverse;
                align-items: initial;
                width: 350px;
                position: absolute;
                top: 100%;
                right: 0;
                padding: 10px;
                border-radius: 5px 0px 5px 5px;
                background-color: #fff;
                visibility: hidden;
                opacity: 0;
                transform: translateY(-10px);
                transition: max-height 0.5s ease, opacity 0.25s ease, visibility 0.25s ease, transform 0.4s ease;

                &.is-open{
                    transform: translateY(0px);
                    opacity: 1;
                    visibility: visible;
                }
            }

            &--two &__contacts-title{
                flex-basis: 50%;
            }

            &--two &__contacts-item{
                margin: 0 0 10px;

                &:first-child{
                    margin-bottom: 0;
                }
            }
        }
    }

    @media only screen and (min-width: $bp-xl + 1) {
        &--two &__contacts{
            flex-direction: row-reverse;
            margin-right: 10px;
            margin-left: 0px;
            order: 2;
        }

        &--two &__contacts-item{
            align-items: flex-start;
            flex-direction: column;
            margin-right: 10px;
            margin-left: 10px;
        }

        &--two &__contacts-title{
            margin-bottom: 5px;
            margin-right: 0;
        }
    }
}
