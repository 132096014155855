@import "@theme/vars.scss";

.s-form {
    padding-bottom: 30px;

    @media only screen and (max-width: $bp-lg) {
        padding-bottom: 60px;
    }

    &__inner {
        display: flex;

        @media only screen and (max-width: $bp-md) {
            flex-direction: column;
        }
    }

    &__sale {
        position: relative;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 264px;
        background-color: $blue-dark;
        border-radius: 15px;
        padding: 50px 32px;
        margin-right: 15px;
        flex-grow: 1;
        // width: calc(50% - 15px);
        max-width: 60%;
        overflow: hidden;

        @media only screen and (max-width: $bp-xxl) {
            flex-direction: column;
        }

        @media only screen and (max-width: $bp-xl) {
            padding: 40px 24px;
        }

        @media only screen and (max-width: $bp-lg) {
            flex-direction: column;
            padding: 30px;
            min-height: 200px;
        }

        @media only screen and (max-width: $bp-md) {
            max-width: none;
            margin-right: 0;
            margin-bottom: 10px;
        }

        &::after{
            pointer-events: none;
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 12px;
            width: 122px;
            height: 160px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='121' height='158' viewBox='0 0 121 158' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M72.7447 21.3657C72.7447 32.0502 81.4259 40.7314 92.1104 40.7314C102.795 40.7314 111.476 32.0502 111.476 21.3657C111.476 10.6812 102.795 2 92.1104 2C81.6485 2 72.7447 10.6812 72.7447 21.3657Z' fill='%230A5C98'/%3E%3Cpath d='M73.4119 68.5557V170.949C73.4119 181.411 81.8705 189.647 92.1098 189.647C102.572 189.647 110.808 181.188 110.808 170.949V68.5557C110.808 58.0938 102.349 49.8578 92.1098 49.8578C81.8705 49.8578 73.4119 58.3164 73.4119 68.5557Z' fill='%230A5C98'/%3E%3Cpath d='M8.19189 23.1461C8.19189 28.4884 12.6438 32.9403 17.986 32.9403C23.3283 32.9403 27.7802 28.4884 27.7802 23.1461C27.7802 17.8039 23.3283 13.352 17.986 13.352C12.6438 13.352 8.19189 17.5813 8.19189 23.1461Z' fill='%230A5C98'/%3E%3Cpath d='M8.63709 49.635V180.52C8.63709 185.64 12.8664 189.869 17.986 189.869C23.1057 189.869 27.335 185.64 27.335 180.52V49.635C27.335 44.5153 23.1057 40.286 17.986 40.286C12.8664 40.286 8.63709 44.5153 8.63709 49.635Z' fill='%230A5C98'/%3E%3Cpath d='M37.1287 49.8576C37.1287 56.9806 42.9161 62.9906 50.0391 62.9906C57.1622 62.9906 62.9496 57.2032 62.9496 49.8576C62.9496 42.7346 57.1622 36.9471 50.0391 36.9471C43.1387 36.7245 37.1287 42.512 37.1287 49.8576Z' fill='%230A5C98'/%3E%3Cpath d='M37.7971 83.9144V177.404C37.7971 184.304 43.3619 189.869 50.2624 189.869C57.1628 189.869 62.7276 184.304 62.7276 177.404V83.9144C62.7276 77.014 57.1628 71.4491 50.2624 71.4491C43.3619 71.2266 37.7971 76.7914 37.7971 83.9144Z' fill='%230A5C98'/%3E%3C/svg%3E%0A");

            @media only screen and (max-width: $bp-xl) {
                width: 100px;
                height: 130px;
            }

            @media only screen and (max-width: $bp-lg) {
                width: 68px;
                height: 88px;
            }
        }
    }

    &__sale-main{
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: $bp-lg) {
            flex-direction: column;
        }
    }

    &__sale-amount {
        position: relative;
        z-index: 1;
        font-weight: 700;
        font-size: 110px;
        line-height: 85%;
        color: #fff;
        margin-right: 20px;
        flex-shrink: 0;

        @media only screen and (max-width: $bp-xxl) {
            font-size: 100px;
            margin-bottom: 10px;
        }

        @media only screen and (max-width: $bp-xl) {
            font-size: 85px;
        }

        @media only screen and (max-width: $bp-lg) {
            font-size: 65px;
        }

        @media only screen and (max-width: $bp-md) {
            font-size: 50px;
        }
    }

    &__sale-title {
        position: relative;
        z-index: 1;
        font-size: 28px;
        line-height: 125%;
        color: #E8EDFD;

        // @media only screen and (max-width: $bp-xxl) {
        //     font-size: 24px;
        // }

        @media only screen and (max-width: $bp-xl) {
            font-size: 22px;
        }

        @media only screen and (max-width: $bp-lg) {
            font-size: 20px;
        }

        @media only screen and (max-width: $bp-md) {
            font-size: 16px;
        }
    }

    &__sale-img{
        display: block;
        position: absolute;
        width: 350px;
        height: 350px;
        right: -24px;
        bottom: -55px;
        object-position: bottom right;
        object-fit: contain;

        @media only screen and (max-width: $bp-xxl) {
            width: 320px;
            height: 320px;
            right: -12px;
        }

        @media only screen and (max-width: $bp-xl) {
            width: 260px;
            height: 260px;
            right: 0;
        }

        @media only screen and (max-width: $bp-lg) {
            width: 180px;
            height: 180px;
            bottom: -45px;
        }

        @media only screen and (max-width: $bp-md) {
            width: 110px;
            height: 110px;
            right: 10px;
            bottom: -55px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: bottom right;
        }
    }

    &__main {
        box-sizing: border-box;
        display: flex;
        width: 50%;
        padding: 40px 45px;
        background-color: #FFFFFF;
        border: 1px solid #E8EDFD;
        border-radius: 15px;
        box-shadow: 0px 12px 21px rgba(82, 117, 242, 0.04);
        flex-shrink: 0;
        flex-grow: 2;

        @media only screen and (max-width: $bp-xl) {
            padding: 30px 36px;
        }

        @media only screen and (max-width: $bp-lg) {
            flex-direction: column;
            padding: 20px 15px;
        }

        @media only screen and (max-width: $bp-md) {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }

        @media only screen and (max-width: $bp-sm) {
            flex-direction: column;
        }
    }

    &__main-title {
        font-weight: 300;
        font-size: 25px;
        line-height: 145%;
        color: #595959;
        margin-right: 15px;

        @media only screen and (max-width: $bp-xl) {
            font-size: 22px;
        }

        @media only screen and (max-width: $bp-lg) {
            font-size: 18px;
            margin-bottom: 10px;
            margin-right: 0;
        }

        @media only screen and (max-width: $bp-md) {
            font-size: 16px;
            margin-right: 15px;
        }

        @media only screen and (max-width: $bp-sm) {
            margin-right: 0;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        width: 280px;
        flex-shrink: 0;

        @media only screen and (max-width: $bp-lg) {
            width: 100%;
        }

        @media only screen and (max-width: $bp-md) {
            width: 280px;
        }

        @media only screen and (max-width: $bp-sm) {
            width: 100%;
        }

        form{
            display: flex;
            flex-direction: column;
        }

        .input,
        .input-file,
        .checkbox {
            max-width: none;
            margin-bottom: 15px;

            @media only screen and (max-width: $bp-lg) {
                margin-bottom: 10px;
            }

            &:last-child{
                margin-bottom: 0;
            }
        }

        .button{
            margin-top: 5px;

            @media only screen and (max-width: $bp-lg) {
                margin-top: 10px;
                height: 40px;
                font-size: 14px;
            }
        
            @media only screen and (max-width: $bp-md) {
                height: 36px;
            }
            
        }
    }


    &--personal &__sale{
        max-width: none;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 80px;

        @media only screen and (max-width: $bp-xxl) {
            flex-direction: row;
        }

        @media only screen and (max-width: $bp-xl) {
            padding-top: 40px;
            align-items: center;
        }

        @media only screen and (max-width: $bp-lg) {
            flex-direction: column;
            justify-content: center;
            padding-top: 30px;
            padding-left: 50px;
            padding-right: 50px;
        }

        @media only screen and (max-width: $bp-md) {
            padding-left: 60px;
            padding-right: 60px;
            min-height: 150px;
        }
    }

    &--personal &__sale-main{
        flex-direction: column;
        max-width: calc(100% - 300px);

        @media only screen and (max-width: $bp-xxl) {
            max-width: none;
        }
    }

    &--personal &__sale-amount {
        margin-right: 0;
        font-size: 175px;
        line-height: 120%;

        @media only screen and (max-width: $bp-xxl) {
            font-size: 145px;
        }

        @media only screen and (max-width: $bp-xl) {
            font-size: 110px;
        }

        @media only screen and (max-width: $bp-lg) {
            font-size: 75px;
        }

        @media only screen and (max-width: $bp-md) {
            font-size: 50px;
        }
    }

    &--personal &__main{
        flex-direction: column;
        max-width: 456px;
        padding: 30px;

        @media only screen and (max-width: $bp-lg) {
            padding: 20px 15px;
        }

        @media only screen and (max-width: $bp-md) {
            max-width: none;
            flex-direction: row;
        }

        @media only screen and (max-width: $bp-sm) {
            flex-direction: column;
        }
    }

    &--personal &__main-title{
        margin-right: 0;
        margin-bottom: 20px;

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 15px;
        }
        
        @media only screen and (max-width: $bp-md) {
            margin-right: 15px;
        }
    }

    &--personal &__form{
        width: 100%;

        @media only screen and (max-width: $bp-md) {
            width: 280px;
        }

        @media only screen and (max-width: $bp-sm) {
            width: 100%;
        }
    }
}