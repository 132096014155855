@import "@theme/vars.scss";

.sitemap{
    box-sizing: border-box;
    background-color: #252525;
    width: 100%;
    min-height: 100vh;
    padding: 20px 0;
    text-transform: uppercase;

    &__title{
        font-weight: 700;
        font-size: 40px;
        line-height: 140%;
        color: #fff;
        margin-bottom: 24px;
        padding: 0 20px;
    }

    &__nav{
        display: flex;
        flex-direction: column;
    }

    &__link{
        display: inline-flex;
        font-weight: 500;
        align-items: center;
        text-transform: capitalize;
        font-size: 18px;
        line-height: 120%;
        padding: 12px 20px;
        color: $blue-light;
        text-decoration: none;
        transition: color 0.25s ease, padding 0.5s ease, background-color 0.5s ease;

        &:nth-of-type(odd){
            background-color: rgba(147, 201, 255, 0.05);
        }

        &:hover{
            color: $blue-medium;
            padding-left: 32px;
        }
    }
}

// .sitemap {

//   &__container {
//     width: 100%;
//     max-width: 100%;
//     box-sizing: border-box;
//     margin: 0 auto;
//     padding: 64px 20px 80px;

//     @include media(from_m) {
//       max-width: 834px;
//       padding: 104px 40px 144px;
//     }

//     @include media(from_l) {
//       max-width: 1440px;
//       padding: 120px 100px 160px;
//     }
//   }
  
//   &__title {
//     margin: 0 0 20px;
//     font-size: 40px;

//     @include media(from_m) {
//       margin: 0 0 28px;
//       font-size: 56px;
//     }

//     @include media(from_l) {
//       margin: 0 0 32px;
//       font-size: 72px;
//     }
//   }

//   &__nav {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//   }

//   &__link {
//     padding: 12px;
//     transform: translateX(-12px);
//     font-size: 20px;
//     color: #204bd8;

//     transition: .2s ease-in-out;

//     @include media(from_m) {
//       padding: 16px;
//       transform: translateX(-16px);
//       font-size: 24px;
//     }

//     @include media(from_l) {
//       padding: 20px;
//       transform: translateX(-20px);
//       font-size: 28px;
//     }

//     &:active,
//     &:hover {
//       opacity: .4;
//       transform: translateX(0);
//       cursor: pointer;
//     }
//   }
// }
