@import "@theme/vars.scss";

.c-additional {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #E8EDFD;
    color: $dark;
    border-radius: 10px;
    padding: 26px 30px;
    color: #595959;

    @media only screen and (max-width: $bp-lg) {
        padding: 20px 25px;
    }

    @media only screen and (max-width: $bp-md) {
        border-radius: 7px;
        padding: 15px;
    }

    &>*{
        margin-bottom: 10px;

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 8px;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }

    h3, &__title{
        color: $blue-dark;

        @media only screen and (max-width: $bp-md) {
            font-size: 16px;
        }
    }

    p, &__text{
        font-size: 16px;
        line-height: 135%;
        font-weight: 300;
    
        @media only screen and (max-width: $bp-lg) {
            font-size: 14px;
            line-height: 145%;
        }

        span{
            font-weight: 400;
        }
    }
}

.c-additional._has_link {
  text-decoration: none;
  transition: 0.2s ease-in-out;

  &:hover,
  &:active {
    opacity: 0.8;
  }
}
