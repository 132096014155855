@import "@theme/vars.scss";

.user-bio {

  &__desc-item {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__desc-item:not(:last-child) {
    margin-bottom: 20px;

    @media only screen and (max-width: $bp-lg) {
      margin-bottom: 10px;
    }
  }

  &__desc-value {
    flex-shrink: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(28, 28, 28, 0.6);
  }

  &__desc-value:first-child {
    min-width: 80px;
    margin-right: 15px;
  }
}
