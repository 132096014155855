@import "@theme/vars.scss";

.header-nav {
    display: flex;
    margin: 0 -20px -10px;
    flex-direction: column;
    align-items: initial;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    padding: 0 20px;
    overflow: hidden auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    &__group {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 20px 10px;

        @media only screen and (max-width: $bp-lg) {
            margin: 0 0 10px;
        }
    }

    &__header {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 135%;
        color: $dark-gray;
        fill: $blue-dark;
        transition: color 0.25s ease, fill 0.25s ease, text-shadow 0.25s ease;
        font-weight: 700;
        text-transform: uppercase;
        justify-content: space-between;

        @media only screen and (max-width: $bp-lg) {
          font-size: 14px;
          justify-content: space-between;
        }

        .icon{
            width: 22px;
            height: 22px;
            margin-left: 12px;
            transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            display: flex;
        }
    }

    &__body{
        display: flex;
        flex-direction: column;
        z-index: 2;
        background-color: #fff;
        opacity: 0;
        transform: translateY(0px);
        overflow: hidden;
        max-height: 0;
        position: relative;
        top: 0;
        left: 0;
        max-width: none;
        box-shadow: none;
        border-radius: 0;
        visibility: visible;
        transition: max-height 0.5s ease;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        width: 252px;

        @media only screen and (max-width: $bp-lg) {
            padding: 10px 0;
            width: 100%;
        }

        a{
            display: inline-flex;
            align-items: center;
            color: $dark;
            font-weight: 400;
            text-decoration: none;
            margin-bottom: 5px;
            transition: color 0.25s ease;

            &:hover{
                color: $blue-dark;
            }


            @media only screen and (max-width: $bp-lg) {
                font-size: 14px;
                color: #595959;
                margin-bottom: 10px;

                &:hover{
                    color: $dark;
                }
            }

            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    &__group:hover &__body{
            opacity: 1;
            visibility: visible;
        }

    &__group:hover &__header{
      text-shadow: $dark-gray 0 0 0.5px;
    }


    &__group.is-open &__header .icon{
       transform: rotate(-180deg);
    }

    &__group.is-open &__body{
       opacity: 1;
    }

}
