@import "@theme/vars.scss";

.s-intro {
	padding-bottom: 30px;

	@media only screen and (max-width: $bp-lg) {
		padding-top: 30px;
	}

	&__inner {
		position: relative;
		display: flex;
		align-items: center;
		min-height: 400px;
		overflow: hidden;
		
		@media only screen and (max-width: $bp-lg) {
			min-height: 320px;
		}

		@media only screen and (max-width: $bp-md) {
			flex-direction: column;
			justify-content: center;
			padding-bottom: 45px;
		}
	}

	&__main {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex-shrink: 0;
		width: 100%;
		max-width: 584px;
		margin-right: 32px;

		@media only screen and (max-width: $bp-xl) {
			max-width: 520px;
		}

		@media only screen and (max-width: $bp-lg) {
			max-width: 50%;
			margin-right: 24px;
		}

		@media only screen and (max-width: $bp-md) {
			max-width: none;
			align-items: center;
			text-align: center;
			margin-bottom: 30px;
			margin-right: 0;
		}

		&>*{
			&:last-child{
				margin-bottom: 0;
			}
		}
	}

	&__title {
		font-weight: 400;
		color: $blue;
		margin-bottom: 20px;

		span{
			display: flex;
			font-weight: 300;
			color: $dark-gray;
		}
	}

	&__text {
		@media only screen and (max-width: $bp-lg) {
			font-size: 16px;
		}

		@media only screen and (max-width: $bp-md) {
			font-size: 14px;
		}
	}

	&__image {
		display: flex;
		width: 100%;
		width: 50%;
		max-height: 530px;
		max-width: 530px;
		object-fit: contain;
		position: absolute;
		top: 0;
		right: 0;

		@media only screen and (max-width: $bp-md) {
			position: relative;
			left: 0;
			width: 100%;
			max-width: 450px;
			margin-bottom: 30px;
		}

		img{
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__button{
		margin-top: 20px;
		
		@media only screen and (max-width: $bp-md) {
			position: absolute;
			bottom: 0;
			margin-top: 0;
		}

		@media only screen and (max-width: $bp-sm) {
			width: 100%;
		}
	}
}
