@import "@theme/vars.scss";

.badges {

    &__list{

        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px -10px;

        @media only screen and (max-width: $bp-lg) {
            margin: 0 -4px -8px;
        }

        &>*{
            margin: 0 5px 10px;

            @media only screen and (max-width: $bp-lg) {
                margin: 0 4px 8px;
            }
        }
    }

    &--group{
        border: 1px solid $blue-dark;
        border-radius: 5px;

        .badge__wrapper{
            border: 0 !important;
        }
    }

    &--group &__list{
        margin-left: 0;
        margin-right: 0;

        &>*{
            margin-right: 0;
            margin-left: 0;
            flex-grow: 1;
        }
    }

    &--group#{&}--white{
        border: 1px solid #fff;
    }

}

.badge {
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    height: 64px;
    text-decoration: none;
    font-family: "HelveticaNeue", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 135%;
    color: #003042;
    fill: #003042;
    white-space: nowrap;


    @media only screen and (max-width: $bp-lg) {
        height: 38px;
        font-size: 14px;
    }

    @media only screen and (max-width: $bp-md) {
        height: 34px;
    }

    &__input {
        display: none;
    }

    &__input[disabled] + &__wrapper{
        cursor: default;
        pointer-events: none;
        opacity: 0.3;
    }

    &__input[disabled]:checked + &__wrapper{
        opacity: 1;
    }

    &__wrapper {
        cursor: pointer;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border: 1px solid $blue;
        text-decoration: none;
        background-color: #fff;
        color: $blue;
        fill: $blue;
        transition: background-color 0.25s ease, color 0.25s ease 0.05s, fill 0.25s ease 0.05s, border-color 0.25s ease, opacity 0.25s ease;
        padding: 0 20px;
        border-radius: 10px;

        &:hover{
            color: $blue-medium;
            fill: $blue-medium;
            background-color: $blue-dark;
        }

        div.icon{
            mask-size: 100% 100%;
            mask-position: center;
            mask-repeat: no-repeat;
            background-color: $blue;
            transition: background-color 0.25s ease 0.075s;
        }

        .icon{
            width: 24px;
            height: 24px;

            @media only screen and (max-width: $bp-lg) {
                width: 20px;
                height: 20px;
            }

            @media only screen and (max-width: $bp-md) {
                width: 16px;
                height: 16px;
            }
        }

        span + .icon {
            margin-left: 6px;
        }

        .icon + span {
            margin-left: 6px;
        }
    }

    // md

    &--md{
        height: 47px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        @media only screen and (max-width: $bp-md) {
            height: 34px;
        }
    }


    // lg

    &--lg{
        height: 50px;

        @media only screen and (max-width: $bp-lg) {
            height: 42px;
        }

        @media only screen and (max-width: $bp-md) {
            height: 34px;
        }
    }

    &--lg &__wrapper{
        padding: 0 20px;

        @media only screen and (max-width: $bp-lg) {
            padding: 0 15px;
        }
    }

    // button

    &--button{
        height: 44px;
    }

    &--button &__wrapper{
        border-radius: 0;
        padding: 0 16px;
        // background-color: $light;
        // color: $dark-light;
        // fill: $dark-light;
        border: 1px solid #E5E7EB;

        &:hover{
            // background-color: $light-light;
        }

        &:active{
            background-color: #E5E7EB;
            border-color: #D1D5DB;
        }
    }

    // actions

    &__input:checked + &__wrapper,
    &.is-active &__wrapper {
        border: 1px solid $blue;
        background-color: $blue;
        color: #FFFFFF;
        box-shadow: 0 12px 21px rgba(82, 117, 242, 0.58);

        &:hover{
            background-color: $blue-dark;
            border-color: $blue-dark;
        }
    }

    // White

    &--white &__wrapper{
        background-color: #fff;
        border-color: #fff;
        color: $blue-dark;
        fill: $blue-dark;

        &:hover{
            background-color: $blue-white;
            border-color: $blue-white;
        }
    }

    &--white &__input:checked + &__wrapper,
    &--white.is-active &__wrapper {
        fill: #fff;
        color: #fff;
        background-color: $blue-dark;

        &:hover{
            color: $blue-medium;
            fill: $blue-medium;
            border-color: $blue-medium;
        }
    }

    // &__input[type='checkbox']:checked + &__wrapper,{
    //     &:hover{
    //         background-color: $blue-dark;
    //     }
    // }

    // &--white &__input[type='checkbox']:checked + &__wrapper{
    //     &:hover{
    //         background-color: $blue;
    //     }
    // }
}
