@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInTop {

    0% {
        opacity: 0;
        transform: translateY(-15px);
    }
  
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
  
@keyframes fadeOut {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes rotate {
    0%{
        rotate: 0deg;
    }

    100%{
        rotate: 360deg;
    }
}