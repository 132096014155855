@import "@theme/vars.scss";

.footer{
	box-sizing: border-box;
  padding: 35px 0 35px;
	background-color: #fff;
	border-top: 3px solid #F0F0F0;


	@media only screen and (max-width: $bp-xl) {
		padding-bottom: 55px;
	}

	@media only screen and (max-width: $bp-lg) {
		padding: 30px 0;
		border-top: 0;
	}

	&__container {
	}

	&__inner {
		display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 50px;

		@media only screen and (max-width: $bp-lg) {
			display: flex;
			flex-direction: column;
      gap: 4px;
		}

		&::after{
			content: "";
			display: block;
			clear: both;
		}
	}

	&__logo {
		float: left;
		width: 102px;
		height: 45px;
		flex-shrink: 0;
		margin-bottom: 10px;
	}

	&__info {
		display: flex;
    gap: 10px;
    width: 100%;
    justify-content: space-between;

		@media only screen and (max-width: $bp-lg) {
			width: 100%;
      flex-direction: column;
		}
	}

	&__contacts {
		display: flex;
    gap: 30px;

		@media only screen and (max-width: $bp-lg) {
			flex-wrap: wrap;
		}
	}


    &__contacts-item {
        display: flex;
		    flex-direction: column;
        font-size: 14px;
        line-height: 135%;

        @media only screen and (max-width: $bp-lg) {
          font-size: 12px;
        }
    }

    &__contacts-title {
        color: #8C8C8C;
        margin-bottom: 5px;
    }

    &__contacts-content {
        display: flex;
        align-items: center;

        &>*{
            margin-right: 5px;

            @media only screen and (max-width: $bp-lg) {
                margin-right: 10px;
            }

            &:last-child{
                margin-right: 0;
            }
        }

        a{
            display: inline-flex;
            align-items: center;
            color: $dark-gray;
            fill: $blue-dark;
            text-decoration: none;
            transition: fill 0.25s ease, color 0.25s ease;

            &:hover{
                color: $blue;
                fill: $blue;
            }

            .icon{
                width: 20px;
                height: 20px;

                @media only screen and (max-width: $bp-lg) {
                    width: 35px;
                    height: 35px;
                }
            }

            .icon + span {
                margin-left: 5px;
            }

            span + .icon{
                margin-left: 5px;
            }
        }
    }

	&__rights {
		font-size: 14px;
		line-height: 135%;
		color: #595959;
		margin-bottom: 5px;

		@media only screen and (max-width: $bp-lg) {
			font-size: 12px;
		}
	}

	&__policy {
		font-size: 14px;
		line-height: 135%;
	}

	&__nav {
		display: flex;
		float: right;
		width: calc(100% - 165px - 25px);
		margin: 0 -8px -20px 16px;

		@media only screen and (max-width: $bp-xl) {
			flex-wrap: wrap;
		}

		@media only screen and (max-width: $bp-lg) {
			width: auto;
			min-width: 100%;
			margin: 0 -10px 10px;
		}

		@media only screen and (max-width: $bp-md) {
			margin-right: -5px;
			margin-left: -5px;
		}
	}

	&__nav-group {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		font-size: 14px;
		line-height: 135%;
		margin: 0 0 20px;
		padding: 0 8px;

		@media only screen and (max-width: $bp-lg) {
			padding: 0 10px;
			flex: 1 0 33.33%;
			font-size: 12px;
		}

		@media only screen and (max-width: $bp-md) {
			padding: 0 5px;
			flex-basis: 50%;
		}
	}

	&__nav-title {
		font-weight: 700;
		color: $dark-gray;
		margin-bottom: 10px;

		@media only screen and (max-width: $bp-lg) {
			margin-bottom: 15px;
		}
	}

	&__nav-content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		a{
			display: inline-flex;
            align-items: center;
            color: $dark;
            font-weight: 400;
            text-decoration: none;
            margin-bottom: 10px;
            transition: color 0.25s ease;

            &:hover{
                color: $blue-dark;
            }

            &:last-child{
                margin-bottom: 0;
            }
		}
	}
}
