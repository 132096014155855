@mixin font($name, $weight, $fileName) {
    @font-face {
        font-family: $name;
        src: local("#{$name}"),
            url("@fonts/#{$name}/#{$fileName}.woff2") format("woff2"),
            url("@fonts/#{$name}/#{$fileName}.woff") format("woff"),
            url('@fonts/#{$name}/#{$fileName}.ttf') format('ttf');
        font-weight: $weight;
        font-style: normal;
        font-display: swap;
    }
}


// Helvetica

@include font("HelveticaNeue", 300, "HelveticaNeue-Light");
@include font("HelveticaNeue", 400, "HelveticaNeue-Regular");
@include font("HelveticaNeue", 700, "HelveticaNeue-Bold");

// // Helvetica

// @include font("HelveticaNeue", 400, "HelveticaNeue-Regular");

// CenturyGothic

@include font("CenturyGothic", 400, "CenturyGothic");
@include font("CenturyGothic", 400, "CenturyGothic-Bold");


