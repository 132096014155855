@import "@theme/vars.scss";

.list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    &__item {
        display: flex;
        align-items: flex-start;
        color: $blue;
        padding: 0;
        margin: 0 0 5px;

        &:last-child{
            margin-bottom: 0;
        }

        &::before{
            content: "";
            display: inline-flex;
            flex-shrink: 0;
            width: 6px;
            height: 6px;
            border-radius: 2px;
            background-color: $blue;
            margin: 8px 10px;

            @media only screen and (max-width: $bp-lg) {
                width: 3px;
                height: 3px;
                margin: 8px;
            }
        }
    }
}