@import "@theme/vars.scss";

button,
.button,
a {
    cursor: pointer;
    padding: 0;
    background: none;
    border: none;
    outline: none;
}

.button {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    background-color: $blue;
    color: #fff;
    fill: #fff;
    box-shadow: 0 12px 21px rgba(82, 117, 242, 0.58);
    transition: color 0.25s ease, fill 0.25s ease, border-color 0.25s ease, background-color 0.25s ease, opacity 0.25s ease, box-shadow 0.35s ease;
    padding: 0 20px;
    height: 54px;
    border-radius: 10px;

    &:hover{
        background-color: $blue-dark;
        box-shadow: 0 12px 21px rgba(82, 117, 242, 0.08);
    }

    &__md {
      height: 47px;
    }

    &--lg{
        height: 55px;
        font-size: 18px;

        @media only screen and (max-width: $bp-lg) {
            font-size: 16px;
            height: 45px;
        }

        @media only screen and (max-width: $bp-md) {
            height: 38px;
        }
    }

    // &--sm{
    //     height: 44px;
    // }


    &__text{

    }

    &__icon{
        flex-shrink: 0;
        width: 24px;
        height: 24px;
    }

    &__text + &__icon{
        margin-left: 6px;
    }

    &__icon + &__text{
        margin-left: 6px;
    }

    &[disabled]{
        pointer-events: none;
        opacity: 0.5;
    }


    &--dark {
        background-color: $blue-dark;

        &:hover{
            background-color: $blue;
        }
    }

    &--white {
        background-color: #fff;
        color: $blue;
        fill: $blue;
        box-shadow: 0px 12px 34px rgba(0, 62, 138, 0.9);

        &:hover{
            background-color: $blue-white;
            box-shadow: 0px 12px 34px rgba(0, 62, 138, 0.25);
        }
    }


    &--border{
        color: $blue;
        fill: $blue;
        border: 1px solid $blue;
        background-color: transparent;
        box-shadow: none;

        &:hover{
            border-color: $blue-dark;
            color: #fff;
            fill: #fff;
        }
    }

    // &--border#{&}--white{
    //     border-color: #fff;
    //     fill: #fff;
    //     color: #fff;
    //     background-color: transparent;

    //     &:hover{
    //         border-color: #fff;
    //         background-color: #fff;

    //     }
    // }

    &--icon{
        padding: 0;
        width: 45px;
    }
}
