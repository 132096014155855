@import '@theme/vars.scss';

h1, h2, h3, h4, h5, h6, p {
    font-weight: 400;
    margin: 0;
    padding: 0;
}

p{
    font-family: 'HelveticaNeue';
    font-weight: 400;
}


.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'HelveticaNeue';
}

.h1{
    font-size: 45px;
    line-height: 135%;

    @media only screen and (max-width: $bp-lg) {
        font-size: 38px;
    }

    @media only screen and (max-width: $bp-md) {
        font-size: 30px;
    }
}

.h2 {
    font-size: 35px;
    line-height: 145%;

    &_m {
      font-size: 45px;
      line-height: 55px;
      font-weight: 700;
      font-family: "CenturyGothic", sans-serif;
    }

    @media only screen and (max-width: $bp-lg) {
        font-size: 30px;
        line-height: 135%;
    }

    @media only screen and (max-width: $bp-md) {
        font-size: 24px;
    }
}

.h3{
    font-size: 25px;
    line-height: 145%;

    @media only screen and (max-width: $bp-lg) {
        font-size: 22px;
    }
}

// .h4{
//     font-size: 25px;
//     line-height: 31px;

//     @media only screen and (max-width: $bp-lg) {
//         font-size: 22px;
//         line-height: 27px;
//     }

//     @media only screen and (max-width: $bp-md) {
//         font-size: 20px;
//         line-height: 25px;
//     }
// }

// .h5{
//     font-size: 20px;
//     line-height: 135%;

//     @media only screen and (max-width: $bp-lg) {
//         font-size: 18px;
//     }

//     @media only screen and (max-width: $bp-md) {
//         font-size: 16px;
//     }
// }

.title_m {
  color: $dark-gray;
  font-size: 35px;
  font-weight: 400;
  line-height: 41px;

  @media only screen and (max-width: $bp-md) {;
    font-size: 24px;
    line-height: 35px;
  }
}

.text{
    font-size: 16px;
    line-height: 135%;

    &-medium {
      font-weight: bold;
      text-transform: uppercase;
    }

    @media only screen and (max-width: $bp-lg) {
        font-size: 14px;
        line-height: 145%;
    }

    &-xl{
        font-size: 20px;
        line-height: 135%;
    }

    &-lg{
        font-size: 18px;
        line-height: 135%;

        @media only screen and (max-width: $bp-lg) {
            font-size: 16px;
        }

        @media only screen and (max-width: $bp-md) {
            font-size: 14px;
            line-height: 145%;
        }
    }

    &-md{
        font-size: 14px;
        line-height: 145%;
    }

    &-sm{
        font-size: 12px;
        line-height: 140%;
    }

    //STYLE

    &-border{
        -webkit-text-stroke: 1px #8C8C8C;
    }

    //WEIGHT

    &-300{
        font-weight: 300;
    }

    &-400{
        font-weight: 400;
    }

    &-500{
        font-weight: 500;
    }

    &-700{
        font-weight: 700;
    }

    //FAMILY

    &-helvetica{
        font-family: 'Helvetica';
    }
}
