@import "@theme/vars.scss";

.input-file {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 135%;

    &__input {
        display: none;
    }

    &__button {
        display: flex;
        align-items: center;
        color: $blue-dark;
        fill: $blue-dark;
        transition: color 0.25s ease, fill 0.25s ease;

        &:hover{
            color: $blue;
            fill: $blue;
        }

        .icon{
            border-radius: 50%;
            width: 26px;
            height: 26px;
            flex-shrink: 0;
            margin-right: 5px;
            box-shadow: 0px 12px 21px rgba(82, 117, 242, 0.28);
        }
    }

    &__content {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }

    &__item{
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 10px;
        animation: fadeInTop 0.45s ease forwards;

        &:first-child{
            margin-top: 15px;
        }
    }

    &__name {
        color: $dark-gray;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
    }

    &__delete {
        fill: $blue-dark;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin-right: 10px;
        transition: fill 0.25s ease;

        &:hover{
            fill: $blue;
        }
    }
}