@import "@theme/vars.scss";

.ui{
    padding: 20px 0 60px;
    // background-color: $dark;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    display: block;
    overflow: visible !important;


    &__header{
        box-sizing: border-box;
        padding: 20px 0;
        min-width: 100%;
        position: sticky;
        top: 56px;
        z-index: 97;
        background-color: #FAFAFA;
        border-bottom: 1px solid $blue-medium;

        @media only screen and (max-width: $bp-lg) {
            padding: 12px 20px;
            top: 4px;
            margin-left: -20px;
            margin-right: -20px;
        }

        @media only screen and (max-width: $bp-md) {
            top: 14px;
        }
    }

    &__title{
        height: 52px;

        @media only screen and (max-width: $bp-lg) {
            height: 42px;
        }

        @media only screen and (max-width: $bp-md) {
            height: 32px;
        }
    }

    &__nav{
        box-sizing: border-box;
        padding: 20px 0 0;
        background-color: #FAFAFA;
        min-width: 100%;

        .badges{
            overflow: hidden;
        }
        
        .badges__list{
            max-height: 108px;
            overflow: hidden auto;

            @media only screen and (max-width: $bp-lg) {
                max-height: 54px;
            }
        }
    }

    &__content{
        overflow: hidden;
    }

    &__block{
        margin-top: 30px;
        border-bottom: 1px solid $blue;
        padding-bottom: 30px;

        &:first-of-type{
            margin-top: 0;
        }

        &::before{
            content: "";
            pointer-events: none;
            display: block;
            height: calc(168px + 130px + 30px);
            margin-top: calc(-1 * (168px + 130px + 30px));
            flex-shrink: 0;
            display: flex;
    
            @media only screen and (max-width: $bp-lg) {
                height: calc(62px + 80px + 30px);
                margin-top: calc(-1 * (62px + 80px + 30px));
            }
        }
    }

    &__row{
        padding-top: 20px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px -10px;
    }

    &__col{
        box-sizing: border-box;
        position: relative;
        max-width: 100%;
        flex-grow: 1;
        padding: 0 20px;
        margin-bottom: 20px;
        min-height: 50px;
    }
}