@import "@theme/vars.scss";

.select{
    box-sizing: content-box;
    height: 45px;
    width: 100%;
    display: flex;
    position: relative;
    font-size: 20px !important;
    color: #595959;

    &.is-error {
        padding-bottom: 20px;
    }

    &.is-error &__error{
        opacity: 1;
        transform: translateY(-12px);
    }

    &.is-focus &__error{
        opacity: 0;
        transform: translateY(0px);
    }

    &.is-focus .select2-selection,
    &:hover .select2-selection{
    // &.is-filled .select2-selection {
        border-color: $blue;
    }

    &.is-error .select2-selection {
        border-color: $red;
    }

    &--lg{
        height: 56px;
        font-size: 16px !important;
    }

    &--lg &__label{
        top: 12px;
        font-size: 16px;
        left: 14px;
    }

    &--lg .select2-selection{
        padding: 0 16px;
    }

    &--lg .select2-results{
        &__option{
            height: 56px;
            padding: 0 16px;
        }
    }

    &--lg .select2-search{
        height: 56px;

        &__field{
            padding: 0 52px 0 16px !important;
        }
    }

    &--search{
        .select2-search{
            display: block !important;
        }
    }


    .select2{

        &-selection{
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 18px;
            border: 1px solid $blue-medium;
            border-radius: 5px !important;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: border-color 0.25s ease;

            &:hover{
                border-color: $blue-light;
            }

            &__arrow{
                top: 50% !important;
                width: 24px !important;
                height: 24px !important;
                flex: 0 0 auto;
                right: 16px !important;
                transform: translateY(-50%);

                b{
                    display: none;
                }

                &::after{
                    content: "";
                    display: block;
                    width: 24px;
                    height: 24px;
                    flex: 0 0 auto;
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9927 16.7881C11.8378 16.7883 11.6844 16.7578 11.5412 16.6985C11.3981 16.6392 11.2681 16.5522 11.1587 16.4425L3.91793 9.20049C3.70246 8.97813 3.58305 8.67996 3.58549 8.37033C3.58792 8.0607 3.712 7.76445 3.93095 7.5455C4.14989 7.32656 4.44615 7.20248 4.75577 7.20005C5.0654 7.19761 5.36357 7.31702 5.58593 7.5325L11.9927 13.9405L18.3995 7.5325C18.6219 7.31702 18.9201 7.19761 19.2297 7.20005C19.5393 7.20248 19.8356 7.32656 20.0545 7.5455C20.2735 7.76445 20.3975 8.0607 20.4 8.37033C20.4024 8.67996 20.283 8.97813 20.0675 9.20049L12.8267 16.4425C12.7173 16.5522 12.5873 16.6392 12.4442 16.6985C12.3011 16.7578 12.1477 16.7883 11.9927 16.7881Z' fill='%2300528E'/%3E%3C/svg%3E%0A");
                    transition: transform 0.35s ease;
                }
            }

            &__rendered{
                width: 100%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                box-sizing: border-box;
                padding: 0 48px 0 18px !important;
                color: inherit !important;
            }

            &__choise{
                margin: 0;
            }
        }
    }

    &--blue .select2{

        &-selection{
            background-color: $blue-dark;
            border-color: #fff;
            color: #fff;

            &:hover{
                border-color: $blue-white;
            }

            &__arrow{
                &::after{
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9927 16.7881C11.8378 16.7883 11.6844 16.7578 11.5412 16.6985C11.3981 16.6392 11.2681 16.5522 11.1587 16.4425L3.91793 9.20049C3.70246 8.97813 3.58305 8.67996 3.58549 8.37033C3.58792 8.0607 3.712 7.76445 3.93095 7.5455C4.14989 7.32656 4.44615 7.20248 4.75577 7.20005C5.0654 7.19761 5.36357 7.31702 5.58593 7.5325L11.9927 13.9405L18.3995 7.5325C18.6219 7.31702 18.9201 7.19761 19.2297 7.20005C19.5393 7.20248 19.8356 7.32656 20.0545 7.5455C20.2735 7.76445 20.3975 8.0607 20.4 8.37033C20.4024 8.67996 20.283 8.97813 20.0675 9.20049L12.8267 16.4425C12.7173 16.5522 12.5873 16.6392 12.4442 16.6985C12.3011 16.7578 12.1477 16.7883 11.9927 16.7881Z' fill='%23fff'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }

    &--blue.is-focus .select2-selection,
    &--blue:hover .select2-selection{
    // &.is-filled .select2-selection {
        border-color: $blue-light;
    }
}



.select2{
    color: #595959;

    &-dropdown{
        border: 0 !important;
        border-radius: 5px !important;
        overflow: hidden;
        box-shadow: 0px 12px 15px rgba(243, 243, 243, 0.28);
    }

    &-container{
        display: block;
        z-index: 2;
        font-size: 16px;
        font-weight: 300;
        line-height: 145%;
        color: inherit;
        animation: fadeIn 0.35s ease forwards;

        &--open{
            z-index: 51;
        }
    }

    &-container--open .select2-selection__arrow::after{
        transform: rotate(-180deg);
    }

    &-search{
        display: none !important;
        height: 64px;
        padding: 0;
        position: relative;

        &::after{
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.00006 11.0011C4.00006 7.13506 7.13407 4.00105 11.0001 4.00105C14.8661 4.00105 18.0001 7.13506 18.0001 11.0011C18.0001 12.887 17.2542 14.5987 16.0414 15.8574C16.0072 15.8838 15.9743 15.9126 15.943 15.9439C15.9116 15.9753 15.8828 16.0082 15.8565 16.0424C14.5978 17.2552 12.886 18.0011 11.0001 18.0011C7.13407 18.0011 4.00006 14.867 4.00006 11.0011ZM16.6177 18.033C15.0781 19.2646 13.1251 20.0011 11.0001 20.0011C6.0295 20.0011 2.00006 15.9716 2.00006 11.0011C2.00006 6.03049 6.0295 2.00105 11.0001 2.00105C15.9706 2.00105 20.0001 6.03049 20.0001 11.0011C20.0001 13.1261 19.2636 15.0791 18.032 16.6187L21.7072 20.2939C22.0977 20.6845 22.0977 21.3176 21.7072 21.7082C21.3166 22.0987 20.6835 22.0987 20.293 21.7082L16.6177 18.033Z' fill='%234BAAF3'/%3E%3C/svg%3E%0A");
        }

        &__field{
            height: 100%;
            padding: 0 56px 0 20px !important;
            border-right: none !important;
            border-left: none !important;
            border-top: none !important;
            border-bottom: 1px solid #E0E0E0 !important;
        }
    }

    &-results{
        &__group{
            color: $dark;
            font-weight: 700;
            padding: 5px 12px !important;
            margin-bottom: 5px;
        }

        &__options{
            max-height: 232px !important;
            scrollbar-width: thin;
            padding: 5px 0;

            &::-webkit-scrollbar {
                height: 4px;
                width: 4px;
            }

            &--nested{
                padding: 0;
            }
        }

        &__option{
            box-sizing: border-box;
            padding: 12px 18px !important;
            min-height: 30px;
            transition: background-color 0.25s ease, color 0.25s ease;

            &:last-child{
                margin-bottom: 0;
            }

            &--highlighted{
                background-color: $blue-white !important;
                color: inherit !important;
            }

            &--selected{
                color: $blue !important;
                background-color: $blue-white !important;
            }

            &--group{
                padding: 0 !important;
                margin-bottom: 10px;
                border-top: 1px solid $blue-white;

                &:first-child{
                    border-top: 0;
                }
            }

        }
    }

    &-selection{
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 18px;
        border: 1px solid $blue-medium;
        border-radius: 5px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: border-color 0.25s ease;

        &:hover{
            border-color: $blue-light;
        }

        &__arrow{
            top: 50% !important;
            width: 24px !important;
            height: 24px !important;
            flex: 0 0 auto;
            right: 16px !important;
            transform: translateY(-50%);

            b{
                display: none;
            }

            &::after{
                content: "";
                display: block;
                width: 24px;
                height: 24px;
                flex: 0 0 auto;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9927 16.7881C11.8378 16.7883 11.6844 16.7578 11.5412 16.6985C11.3981 16.6392 11.2681 16.5522 11.1587 16.4425L3.91793 9.20049C3.70246 8.97813 3.58305 8.67996 3.58549 8.37033C3.58792 8.0607 3.712 7.76445 3.93095 7.5455C4.14989 7.32656 4.44615 7.20248 4.75577 7.20005C5.0654 7.19761 5.36357 7.31702 5.58593 7.5325L11.9927 13.9405L18.3995 7.5325C18.6219 7.31702 18.9201 7.19761 19.2297 7.20005C19.5393 7.20248 19.8356 7.32656 20.0545 7.5455C20.2735 7.76445 20.3975 8.0607 20.4 8.37033C20.4024 8.67996 20.283 8.97813 20.0675 9.20049L12.8267 16.4425C12.7173 16.5522 12.5873 16.6392 12.4442 16.6985C12.3011 16.7578 12.1477 16.7883 11.9927 16.7881Z' fill='%2300528E'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: center;
                transition: transform 0.35s ease;

                @media only screen and (max-width: $bp-md) {
                  background-size: 20px;
                }
            }
        }

        &__rendered{
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            box-sizing: border-box;
            padding: 0 48px 0 18px !important;
            color: inherit !important;
        }

        &__choise{
            margin: 0;
        }

        &__clear{
            display: none !important;
        }
    }
}

.select--blue.select2-dropdown{
    color: #fff;
    background-color: $blue-dark;
    box-shadow: 0px 12px 15px rgba(46, 66, 139, 0.28);
    border: 2px solid #5275F2!important;

    .select2{

        &-container{
            color: #fff;
        }

        &-search{
            &::after{
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.00006 11.0011C4.00006 7.13506 7.13407 4.00105 11.0001 4.00105C14.8661 4.00105 18.0001 7.13506 18.0001 11.0011C18.0001 12.887 17.2542 14.5987 16.0414 15.8574C16.0072 15.8838 15.9743 15.9126 15.943 15.9439C15.9116 15.9753 15.8828 16.0082 15.8565 16.0424C14.5978 17.2552 12.886 18.0011 11.0001 18.0011C7.13407 18.0011 4.00006 14.867 4.00006 11.0011ZM16.6177 18.033C15.0781 19.2646 13.1251 20.0011 11.0001 20.0011C6.0295 20.0011 2.00006 15.9716 2.00006 11.0011C2.00006 6.03049 6.0295 2.00105 11.0001 2.00105C15.9706 2.00105 20.0001 6.03049 20.0001 11.0011C20.0001 13.1261 19.2636 15.0791 18.032 16.6187L21.7072 20.2939C22.0977 20.6845 22.0977 21.3176 21.7072 21.7082C21.3166 22.0987 20.6835 22.0987 20.293 21.7082L16.6177 18.033Z' fill='%23fff'/%3E%3C/svg%3E%0A");
            }

            &__field{
                border-bottom: 1px solid #E0E0E0 !important;
            }
        }

        &-results{


            &__group{
                color: #fff;
            }

            &__options{
               scrollbar-color: #fff transparent;

                &::-webkit-scrollbar-track {
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #fff;
                }
            }

            &__option{

                &--highlighted{
                    background-color: #004c83 !important;
                    color: #fff !important;
                }

                &--selected{
                    background-color: #004c83 !important;
                    color: $blue-medium !important;
                }

                &--group{
                    border-color: #004c83;
                }
            }
        }

        &-selection{
            background-color: $blue-dark;
            border-color: #fff;
            color: #fff;

            &:hover{
                border-color: $blue-white;
            }

            &__arrow{
                &::after{
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9927 16.7881C11.8378 16.7883 11.6844 16.7578 11.5412 16.6985C11.3981 16.6392 11.2681 16.5522 11.1587 16.4425L3.91793 9.20049C3.70246 8.97813 3.58305 8.67996 3.58549 8.37033C3.58792 8.0607 3.712 7.76445 3.93095 7.5455C4.14989 7.32656 4.44615 7.20248 4.75577 7.20005C5.0654 7.19761 5.36357 7.31702 5.58593 7.5325L11.9927 13.9405L18.3995 7.5325C18.6219 7.31702 18.9201 7.19761 19.2297 7.20005C19.5393 7.20248 19.8356 7.32656 20.0545 7.5455C20.2735 7.76445 20.3975 8.0607 20.4 8.37033C20.4024 8.67996 20.283 8.97813 20.0675 9.20049L12.8267 16.4425C12.7173 16.5522 12.5873 16.6392 12.4442 16.6985C12.3011 16.7578 12.1477 16.7883 11.9927 16.7881Z' fill='%23fff'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }
}

.selectSumo {
  width: auto;
  min-width: 172px;
  flex-grow: 1;
  height: 47px;
  font-size: 14px !important;
  line-height: 14px !important;

  .SumoSelect {
    width: 100%;
    height: 100%;

    &:focus > .CaptionCont,
    &:hover > .CaptionCont,
    &.open > .CaptionCont{
      box-shadow: none;
      border-color: #0066B2;
    }
  }

  .SumoSelect[is-selected="true"] > .CaptionCont{
    background-color: #F7F8F9;
  }

  .sumo {

    &_select {
    }
  }

  .SumoUnder {
    display: none;
  }

  .CaptionCont {
  }

  .SumoSelect.open .SelectBox label {
    rotate: -180deg;
    top: 5px;
  }

  .SelectBox {
    cursor: pointer;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    border-radius: 8px;
    border: 1px solid #B6D9FF;
    transition: border-color 0.35s ease;
    position: absolute;
    width: 100%;
    background-color: #F7F8F9;

    &:hover{
      border-color: $blue-light;
    }

    .placeholder{
      font-style: normal !important;
      color: $dark !important;
    }

    label{
      cursor: pointer;
      width: 20px !important;
      height: 20px !important;
      transition:  0.35s ease;
      position: relative;

      i{
        width: 100% !important;
        height: 100% !important;
        opacity: 1 !important;
        background-size: 100% !important;
        background-position: center !important;
        background-image: url(../../../src/assets/sprite/arrow--down.svg) !important;
        position: relative;

        &:before {
          content: "";
          width: 20px;
          height: 20px;
          display: inline-block;
          color: $blue-dark;
        }
      }
    }
  }

  .optWrapper {
    position: absolute;
    z-index: 10;
    box-sizing: border-box;
    background-color: #FFFFFF;
    display: block !important;
    opacity: 0 !important;
    visibility: hidden !important;
    border-radius: 12px;
    box-shadow: 9px 12px 42px 5px rgba(18, 66, 111, 0.09);
    width: 100%;
    padding: 10px;
    top: 100% !important;
    transform: translateY(0);
    transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease;

    .options {
      padding: 0;
      margin: 0;
    }
  }

  .SumoSelect.open .optWrapper{
    opacity: 1 !important;
    visibility: visible !important;
    transform: translateY(15px);
  }

  .options {
    max-height: 410px;
    overflow-y: auto;

    @media only screen and (max-width: $bp-lg) {
      max-height: 284px;
    }
  }

  .opt {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    padding: 5px !important;
    border: none !important;
    color: $blue-dark;
    transition: text-shadow 0.35s ease;

    span{
      position: relative !important;
      flex-shrink: 0;
      width: 25px !important;
      height: 25px !important;
      margin: 3.5px 10px 3.5px 0 !important;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #AEBDCB !important;
      transition: border-color 0.35s ease, background-color 0.35s ease;

      &::after{
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100% !important;
        height: 100% !important;
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.77273 15.7015L6.19318 12.2537L5 13.403L9.77273 18L20 8.14925L18.8068 7L9.77273 15.7015Z' fill='%23262626'/%3E%3C/svg%3E%0A") !important;
        background-size: 100%;
        background-position: center;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.4);
        will-change: transform;
        transition: opacity 0.35s ease, transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;

      }

      i{
        display: none !important;
      }
    }

    label{
      margin: auto 0;
    }

    &:hover{
      background-color: transparent !important;
      text-shadow: 0px 0px 1px $blue-dark;

      span{
        border-color: $blue !important;
      }
    }

    &.selected{
      text-shadow: 0px 0px 1px $blue-dark;

      span{
        border-color: $blue !important;
        background-color: $blue !important;

        &::after{
          content: "";
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
        }
      }
    }
  }

  .selected {
  }
}
