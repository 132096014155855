@import "@theme/vars.scss";

.breadcrumbs {
    display: flex;
    max-width: 100%;
    margin: 20px 0 10px;

    @media only screen and (max-width: $bp-lg) {
        margin: 24px 0 10px;
    }

    &__list {
        display: flex;
        align-items: center;
        padding: 0 32px;
        flex-shrink: 0;
        overflow-x: auto;

        @media only screen and (max-width: $bp-xl) {
            padding: 0;
        }

        @media only screen and (max-width: $bp-lg) {
            padding: 0 25px;
            margin: 0 -25px;
        }

        @media only screen and (max-width: $bp-md) {
            padding: 0 20px;
            margin: 0 -20px;
        }

        -webkit-tap-highlight-color: transparent;
        scrollbar-color: transparent transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            height: 5px;
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
    }

    &__link {
        flex-shrink: 0;
        white-space: nowrap;
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        line-height: 145%;
        color: #7D8C99;
        text-decoration: none;
        transition: color 0.25s ease;

        &::after{
            content: "";
            display: inline-flex;
            width: 16px;
            height: 16px;
            flex-shrink: 0;
            background-size: 100%;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.7998 12L8.7998 8.00002L4.7998 4.00002L5.5998 2.40002L11.1998 8.00002L5.5998 13.6L4.7998 12Z' fill='%230087B2'/%3E%3Cpath d='M4.7998 12L8.7998 8.00002L4.7998 4.00002L5.5998 2.40002L11.1998 8.00002L5.5998 13.6L4.7998 12Z' fill='url(%23paint0_linear_504_3807)' fill-opacity='0.41'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_504_3807' x1='10.3132' y1='14.4214' x2='2.1071' y2='13.7468' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23022D3F'/%3E%3Cstop offset='1' stop-color='%230087B2' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        }

        &:hover{
            color: $blue-dark;
        }

        &:last-of-type{
            color: $blue-dark;
            pointer-events: none;

            &::after{
                display: none;
            }
        }
    }
}
