@import "@theme/vars.scss";

.home {
  padding-bottom: 30px;

  section {
    padding: 30px 0;
  }

  &-slider {

    .counter {
      display: none;

      @media only screen and (max-width: $bp-lg) {;
        display: block;
        position: absolute;
        font-size: 12px;
        line-height: 19px;
        right: 70px;
        bottom: 34px;
        z-index: 33;
        color: $white;
      }
    }

    &-img {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;

      @media only screen and (max-width: $bp-lg) {
        top: 0;
        left: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-subimg {
      position: absolute;
      right: 248px;
      top: 158px;

      @media only screen and (max-width: $bp-lg) {
        top: 60px;
        left: 20vw;
        width: 60%;
      }
    }

    &-text__inner {
      @media only screen and (max-width: $bp-lg) {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }

    .slider {

      &-icon {
        display: none;

        @media only screen and (max-width: $bp-lg) {
          display: block;
          width: 30px;
          height: 30px;
          object-fit: contain;
          border-radius: 50%;
          object-position: center;
        }
      }

      &-buttons {
        display: flex;
        flex-wrap: nowrap;
        margin-top: 24px;
        gap: 16px;

        @media only screen and (max-width: $bp-lg) {
          display: none;
        }
      }

      &-button {
        cursor: pointer;
        box-shadow: 0 4px 34px 0 rgba(85, 153, 236, 0.23);
        border: 1px solid #FFFFFF;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 24px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: border .3s ease, box-shadow .3s ease, color .3s ease;
        flex: 1;

        h4 {
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          color: $blue;
          text-align: center;
          margin-top: 10px;
        }

        &-img {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          overflow: hidden;
          box-shadow: 0 4px 34px 0 rgba(85, 153, 236, 0.23);

          svg {
            width: 56px;
            height: 56px;

            path {
              fill: #00528E;
            }
          }
        }

        &.active {
          box-shadow: none;
          border: 1px solid #00A6E0;

          h4 {
            color: $dark;
          }

          svg {

            path {
              fill: #00A6E0;
            }
          }

          .progress-bar {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background: #00A6E0;
            border-radius: 39px;

            &-line {
              position: relative;
              width: 100%;
              height: 5px;
              background: #E8EDFD;
              border-radius: 39px;
              margin-top: 21px;
            }
          }
        }
      }
    }

    .swiper {

      &-button {
        position: absolute;
        margin: auto 32px;
        background: #FFFFFF;
        box-shadow: 0 4px 34px 0 rgba(0, 62, 138, 0.77);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        top: 182px;
        transition: box-shadow .3s ease;

        @media only screen and (max-width: $bp-lg) {
          width: 45px;
          height: 45px;
          top: auto;
          bottom: 21px;
        }

        &:hover {
          box-shadow: none;
        }

        &-prev {

          &:after {
            content: '';
            width: 100%;
            height: 100%;
            background: url("../../../assets/media/home/arrow-right.svg") center no-repeat;
            transform: rotate(180deg);
          }

          @media only screen and (max-width: $bp-lg) {
            right: 78px;
            left: auto;
          }

        }

        &-next {

          &:after {
            content: '';
            width: 100%;
            height: 100%;
            background: url("../../../assets/media/home/arrow-right.svg") center no-repeat;
          }

          @media only screen and (max-width: $bp-lg) {
            right: -15px;
          }
        }
      }

      &-container {
        overflow: hidden;
      }

      &-wrapper {
      }

      &-slide {
        height: 415px;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        padding: 114px;
        display: flex;
        align-items: center;

        @media only screen and (max-width: $bp-lg) {
          height: calc(100vh - 158px);
          padding: 21px 15px;
          align-items: flex-end;
        }

        &:before, &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 2;
        }

        &:before {
          background: url("../../../assets/media/home/slide-background.png") left center no-repeat;

          @media only screen and (max-width: $bp-lg) {
            background: url("../../../assets/media/home/slide-background-mobile.png") right bottom no-repeat;
            background-size: cover;
            bottom: -30%;
            height: 130%;
          }

          @media only screen and (max-width: $bp-md) {
            height: 100%;
            bottom: 0;
          }
        }

        &:after {
          background: url("../../../assets/media/home/i.png") left center no-repeat;

          @media only screen and (max-width: $bp-lg) {
            display: none;
          }
        }

        .home-slider-text {
          position: relative;
          z-index: 4;
          max-width: 484px;

          @media only screen and (max-width: $bp-lg) {
            max-width: 100%;
          }

          h2 {
            font-size: 45px;
            font-weight: 700;
            line-height: 52px;
            letter-spacing: 0;
            text-align: left;
            color: $white;

            @media only screen and (max-width: $bp-lg) {
              font-size: 24px;
              line-height: 28px;
              max-width: 86%;
            }
          }

          p {
            margin-top: 18px;
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0;
            text-align: left;
            color: $white;

            @media only screen and (max-width: $bp-lg) {
              font-size: 14px;
              line-height: 22px;
              margin-top: 9px;
              max-width: 86%;
            }
          }

          .home-slider__btn {
            margin-top: 24px;
            box-shadow: 0 12px 60px 0 rgba(5, 37, 77, 0.29);
            background: #FFFFFF;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 10px 30px 13px;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            color: $blue-dark;

            @media only screen and (max-width:  $bp-lg) {
              font-size: 14px;
              padding: 10px 30px;
              margin-top: 18px;
              width: calc(100% - 160px);
            }
          }
        }
      }
    }

    .progress-bar {

      @media only screen and (max-width: $bp-lg) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: #00A6E0;
        border-radius: 39px;
      }

      &-line {
        @media only screen and (max-width: $bp-lg) {
          position: relative;
          width: 100%;
          height: 5px;
          background: #E8EDFD;
          border-radius: 39px;
          margin-top: 21px;
        }
      }
    }
  }

  &-services {

    &-items {
      display: flex;
      gap: 20px;
      margin-top: 40px;
      flex-wrap: wrap;

      @media only screen and (max-width: $bp-md) {
        flex-direction: column;
        gap: 16px;
        margin-top: 30px;
      }
    }

    &-item {
      flex-basis: calc(33% - 20px);
      position: relative;
      border: 1px solid rgba(0, 82, 142, 0.3);
      border-radius: 10px;
      box-sizing: border-box;
      padding: 30px;

      @media only screen and (max-width: $bp-md) {
        padding: 20px;
        flex-basis: calc(33% - 16px);
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        z-index: 1;
      }

      &:before {
        left: 0;
        background: url("../../../assets/media/home/serv-back.png") right bottom no-repeat;
      }

      &-text {
        width: 63.5%;
        position: relative;
        z-index: 3;

        @media only screen and (max-width: $bp-md) {
          width: 66.56%;
        }

        h5 {
          font-size: 22px;
          font-weight: 700;
          line-height: 25px;
          letter-spacing: 0;
          text-align: left;
          color: $blue-middle;

          @media only screen and (max-width: $bp-md) {
            font-size: 18px;
            line-height: 21px;
          }
        }

        p {
          margin-top: 15px;
          color: $gray-middle;
          font-size: 16px;
          line-height: 21px;

          @media only screen and (max-width: $bp-md) {
            font-size: 12px;
            line-height: 19px;
            margin-top: 8px;
          }
        }

        a {
          text-decoration: none;
          display: flex;
          align-items: center;
          margin-top: 20px;
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
          gap: 5px;
          max-width: 77px;

          span {
            box-sizing: content-box;
            color: $blue-dark;
            width: 65px;
          }

          svg {
            width: 10px;
            height: 7px;
          }
        }
      }

      &-img  {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 90%;
        width: 35%;
        z-index: 2;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: bottom right;
        }
      }
    }
  }

  &-discount {

    .container {
      display: flex;
      gap: 16px;

      @media only screen and (max-width: $bp-md) {
        flex-direction: column;
        gap: 10px;
      }
    }

    &-poster {
      width: 63.63%;
      background: $blue-dark;
      border-radius: 15px;
      overflow: hidden;
      padding: 76px 58px 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      position: relative;

      @media only screen and (max-width: $bp-md) {
        width: 100%;
        padding: 30px;
        height: 357px;
      }

      &:before, &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        z-index: 2;
      }

      &:before {
        left: 0;
        background: url("../../../assets/media/home/dis-img-1.png") left bottom no-repeat;

        @media only screen and (max-width: $bp-md) {
          background: url("../../../assets/media/home/dis-img-1-mobile.png") left bottom no-repeat;
        }
      }

      &:after {
        background-size: cover;
        right: 0;
        background: url("../../../assets/media/home/question1.png") right bottom no-repeat;

        @media only screen and (max-width: $bp-md) {
          background: url("../../../assets/media/home/question2.png") right bottom no-repeat;
        }
      }
    }

    &__title {
      width: 57.2%;
      position: relative;
      z-index: 10;

      @media only screen and (max-width: $bp-md) {
        width: 100%;
      }

      h2 {
        font-size: 45px;
        font-weight: 700;
        line-height: 52px;
        color: $white;

        @media only screen and (max-width: $bp-md) {
          font-size: 24px;
          line-height: 28px;
        }
      }

      p {
        font-size: 18px;
        line-height: 21px;
        color: $white;
        margin-top: 24px;

        @media only screen and (max-width: $bp-md) {
          font-size: 14px;
          line-height: 22px;
          margin-top: 9px;
        }
      }
    }

    &-form {
      box-shadow: 0 12px 21px 0 rgba(82, 117, 242, 0.04);
      box-sizing: border-box;
      padding: 46px 36px;
      border: 1px solid $blue-white;
      border-radius: 15px;
      width: 35%;

      @media only screen and (max-width: $bp-md) {
        width: 100%;
        padding: 24px 20px;
      }

      &__title {
        font-size: 24px;
        font-weight: 400;
        line-height: 34px;
        color: #595959;

        span {
          font-weight: 700;
          color: $blue-dark;
        }

        @media only screen and (max-width: $bp-md) {
          font-size: 18px;
          line-height: 145%;
        }
      }

      .button {
        @media only screen and (max-width: $bp-md) {
          height: 55px;
          border-radius: 5px;
        }
      }

      .s-form__form {
        margin-top: 24px;
        padding: 0 10px;
        width: 100%;

        .input {
          margin-bottom: 16px;

          &:nth-child(2) {
            margin-bottom: 24px;
          }
        }
      }
    }
  }

  &-promo {

    &_items {
      display: flex;
      margin-top: 40px;
      gap: 16px;

      @media only screen and (max-width: $bp-md) {;
        flex-direction: column;
        margin-top: 30px;
      }

      & .promo-item {
        flex: 1;
        height: 229px;

        @media only screen and (max-width: $bp-md) {;
          height: 180px;
          flex: auto;
        }
      }
    }
  }
}
