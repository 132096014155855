@import "@theme/vars.scss";

.range {

    &__inputs {
        display: flex;
        justify-content: space-between;
    }

    &__input {
        width: max-content;

        .input__field{
            text-overflow: clip;
        }
    }

    &__slider-wrapper {
        box-sizing: border-box;
        padding: 4px 0 0;
        height: 10px;
        width: 100%;
        margin-bottom: 15px;
    }

    &__slider {
        height: 2px;
        border: 0;
        background-color: $blue-dark;
        box-shadow: none;

        .noUi-connects {
            cursor: pointer;
            overflow: visible;
        }

        .noUi-handle {
            box-shadow: none;
        }

        .noUi-base {
            
            z-index: 3;
        }

        .noUi-base::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          transform: translateY(-50%);
          padding: 20px 0;
          width: 100%;
        }

        .noUi-handle::before,
        .noUi-handle::after {
            display: none;
        }

        .noUi-handle .noUi-touch-area {
            position: absolute;
            top: -10px;
            left: -10px;
            right: -10px;
            bottom: -10px;
            width: auto;
            height: auto;
            cursor: grab;
        }

        .noUi-connect {
            background-color: $blue;
            border-radius: 3px;
        }

        .noUi-handle {
            height: 10px;
            width: 10px;
            top: -4px;
            right: -5px;
            border: 0;
            border-radius: 50%;
            background-color: $blue-dark;
            box-shadow: none;
        }

        .noUi-tooltip {
            left: 50%;
            bottom: 100%;
            transform: translate(-50%, -10px);
            display: block;
            width: auto;
            height: auto;
            padding: 4px 8px;
            border: 0;
            z-index: 2;
            background-color: $blue-dark;
            border-radius: 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 135%;
            color: #FFFFFF;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.25s ease, visibility 0.25s ease;

            &::after{
                pointer-events: none;
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 4px 5px 0 5px;
                border-color: $blue-dark transparent transparent transparent;
            }
        }

        .noUi-touch-area:hover + .noUi-tooltip,
        .noUi-active .noUi-tooltip {
            opacity: 1;
            visibility: visible;
        }

        .noUi-pips {
          height: 32px;
        }
        .noUi-marker {
          display: none;
        }

        .noUi-value {
          top: 0;
          font-size: 14px;

          @media only screen and (max-width: $bp-lg) {
            font-size: 10px;
          }
        }

        .noUi-value.noUi-value-large {
          transform: translate(0, 50%);
        }

        .noUi-value.noUi-value-large:last-child {
          transform: translate(-100%, 50%);
        }
    }

    &--scale .noUi-base::after {
      padding: 20px 40px 48px;
      left: 50%;
      transform: translate(-50%, -32px);
    }

    &--white .noUi-value {
      color: #fff;
    }

    &--white &__slider {
        background-color: $blue-white;

        .noUi-connect {
            background-color: $blue-medium;
            border-radius: 3px;
        }

        .noUi-handle {
            background-color: $blue-white;
        }

        .noUi-tooltip {
            background-color: #fff;
            color: $blue-dark;

            &::after{
                border-color: #fff transparent transparent transparent;                
            }
        }
    }
}
