@import "@theme/vars.scss";

.radio {
    cursor: pointer;
    display: flex;

    &__input {
        display: none;
    }

    &__input:checked + &__wrapper &__icon-wrapper{
        border-color: $blue;
    }

    &__input:checked + &__wrapper &__icon{
        opacity: 1;
        background-color: $blue;
        transform: scale(1);
    }

    &:hover &__icon-wrapper{
        border-color: $blue;
    }

    &__wrapper {
        display: flex;
        align-items: flex-start;
    }

    &__icon-wrapper {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid $blue-medium;
        transition: border-color 0.25s ease, background-color 0.25s ease;
    }

    &__icon {
        width: 16px;
        height: 16px;
        opacity: 0;
        background-color: #AEBDCB;
        border-radius: 50%;
        will-change: transform;
        transform: scale(1.5625);
        transition: opacity 0.25s ease, background-color 0.25s ease, transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
    }

    &__content {
        display: flex;
        margin: auto 0 auto 10px;
        
        span{
            display: inline;

            .tooltip-trigger{
                display: inline;
                margin-left: 5px;
            }
        }
    }
}