@import '@theme/vars.scss';

@keyframes constructor-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.s-constructor  {
  position: relative;
  height: 400px;
  overflow: hidden;
}

.s-constructor.shown {
  height: auto;
  overflow: unset;
}

.s-constructor form {
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease-in-out;
}

.s-constructor.shown form {
  opacity: 1;
  pointer-events: all;
}

.s-constructor.shown .constructor-loader {
  opacity: 0;
  pointer-events: none;
}

.constructor-loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  transition: 0.2s ease-in-out;

  .lds-ring {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid $blue;
    border-radius: 50%;
    animation: constructor-preloader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $blue transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
}
