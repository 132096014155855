// COLORS

$dark: #1C1C1C;
$dark-gray: #262626;

$gray: #8C8C8C;
$gray-middle: #3C3C3C;

$white: #fff;

$blue: #0066B2;
$blue-middle: #043984;
$blue-dark: #00528E;
$blue-light: #5275F2;
$green: #52AC62;
$blue-medium: #B6D9FF;
$blue-white: #E8EDFD;

$red: #ff5a5a;
$red-light: #EB5757;



// DOCUMENT
$defaultFontFamily : 'HelveticaNeue', 'Helvetica', sans-serif;
$defaultFontSize : 16px;
$defaultLineHeight: 145%;

// BREAKPOINTS

$bp-sm: 575px;
$bp-md: 833px;
$bp-lg: 1023px;
$bp-xl: 1259px;
$bp-xxl: 1439px;
