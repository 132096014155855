@import "@theme/vars.scss";

.s-advanatages {
    padding-bottom: 30px;

    @media only screen and (max-width: $bp-lg) {
        padding-bottom: 60px;
    }

    &__header {
    }

    &__title {
        color: $dark-gray;
        margin-bottom: 40px;
        
        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 30px;
        }
    }

    &__inner {
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px -20px;

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: -15px;
        }
    }

    &__card{
        width: calc(20% - 10px);
        margin: 0 5px 20px;

        @media only screen and (max-width: $bp-xl) {
            width: calc(25% - 10px);
        }

        @media only screen and (max-width: $bp-lg) {
            width: calc(33.33% - 10px);
            margin-bottom: 15px;
        }
        
        @media only screen and (max-width: $bp-md) {
            width: calc(50% - 10px);
        }

        @media only screen and (max-width: $bp-sm) {
            width: 100%;
        }
    }
}
