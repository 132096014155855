@import "@theme/vars.scss";

.input {
    position: relative;
    box-sizing: border-box;
    background: none;
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 420px;
    height: auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    fill: $blue;
    color: $dark-gray;
    text-decoration: none;
    transition: padding 0.25s ease, max-width 0.25s ease, fill 0.25s ease, color 0.25s ease;

    @media only screen and (max-width: $bp-lg) {
        font-size: 14px;
    }

    // @media only screen and (max-width: $bp-md) {
    //     font-size: 12px;
    // }

    // @media only screen and (max-width: $bp-sm) {
    //     font-size: 10px;
    // }

    &__field{
        position: relative;
        z-index: 2;
        outline: none;
        box-sizing: border-box;
        width: 100%;
        height: 44px;
        padding: 0 18px;
        background-color: transparent;
        border: 1px solid $blue-medium;
        border-radius: 5px;
        color: inherit;
        text-overflow: ellipsis;
        transition: background-color 0.25s ease 0.1s, border-color 0.25s ease, padding 0.25s ease;

        @media only screen and (max-width: $bp-lg) {
            height: 40px;
            padding: 0 12px;
        }

        @media only screen and (max-width: $bp-md) {
            height: 34px;
            padding: 0 6px;
        }

        &:hover{
            border-color: $blue;
        }

        &::placeholder{
            opacity: 1;
            color: #595959;
            transition: opacity 0.25s ease, color 0.25s ease;
        }

        &[disabled]{
            pointer-events: none;
        }

        &[type="time"] {
            cursor: text;
            &::-webkit-calendar-picker-indicator,
            &::-webkit-inner-spin-button,
            &::-webkit-clear-button,
            &::-webkit-outer-spin-button {
                display: none !important;
                -webkit-appearance: none !important;
                -moz-appearance: none !important;
                appearance: none !important;
                margin: 0;
            }
        }

        &:is(textarea) {
            padding: 10px 18px;
            min-height: 116px;
            height: auto;
            resize: vertical;
            overflow: hidden;
        }
    }

    &__icon {
        pointer-events: none;
        position: absolute;
        top: 10px;
        left: 12px;
        z-index: 3;
        width: 24px;
        height: 24px;
        // margin-right: 10px;
        pointer-events: none;

        @media only screen and (max-width: $bp-lg) {
            left: 8px;
            width: 20px;
            height: 20px;
            // margin-right: 8px;
        }

        @media only screen and (max-width: $bp-md) {
            top: 9px;
            left: 6px;
            height: 16px;
            width: 16px;
            // margin-right: 6px;
        }

        &--right{
            left: auto;
            right: 12px;

            @media only screen and (max-width: $bp-lg) {
                right: 8px;
            }

            @media only screen and (max-width: $bp-md) {
                right: 6px;
            }
        }

        &--right + &--right{
            right: 48px;

            @media only screen and (max-width: $bp-lg) {
                right: 36px;
            }

            @media only screen and (max-width: $bp-md) {
                right: 22px;
            }
        }

        &.jsInputSwap{
            cursor: pointer;
            pointer-events: all;
            transition: fill 0.25s ease;

            &.is-active{
                fill: $blue;
            }
        }
    }

    &__icon:not(.input__icon--right) ~ &__field {
        padding-left: calc(12px + 24px + 12px);

        @media only screen and (max-width: $bp-lg) {
            padding-left: calc(8px + 20px + 8px);
        }

        @media only screen and (max-width: $bp-md) {
            padding-left: calc(6px + 16px + 4px);
        }
    }

    &__icon:not(.input__icon--right) + &__icon:not(.input__icon--right) ~ &__field {
        padding-left: calc(12px + 24px + 10px + 24px + 12px);

        @media only screen and (max-width: $bp-lg) {
            padding-left: calc(8px + 20px + 8px + 20px + 8px);
        }

        @media only screen and (max-width: $bp-md) {
            padding-left: calc(6px + 16px + 6px + 16px + 4px);
        }
    }

    &__icon--right ~ &__field {
        padding-right: calc(12px + 24px + 12px);

        @media only screen and (max-width: $bp-lg) {
            padding-right: calc(8px + 20px + 8px);
        }

        @media only screen and (max-width: $bp-md) {
            padding-right: calc(6px + 16px + 4px);
        }
    }

    &__icon--right + &__icon--right ~ &__field {
        padding-right: calc(12px + 24px + 10px + 24px + 12px);

        @media only screen and (max-width: $bp-lg) {
            padding-right: calc(8px + 20px + 8px + 20px + 8px);
        }

        @media only screen and (max-width: $bp-md) {
            padding-right: calc(6px + 16px + 6px + 16px + 4px);
        }
    }

    &__button{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 40px;
        height: 40px;
        right: 0;
        top: 0;
        z-index: 3;

        @media only screen and (max-width: $bp-lg) {
            width: 32px;
            height: 32px;
            right: 8px;
            top: 4px;
        }

        .icon{
            width: 24px;
            height: 24px;

            @media only screen and (max-width: $bp-lg) {
                width: 16px;
                height: 16px;
            }
        }
    }

    &__error{
        box-sizing: border-box;
        display: none;
        position: relative;
        margin-top: 4px;
        z-index: 1;
        color: $red;
        font-size: 14px;
        line-height: 135%;
        width: 100%;
        transition: transform 0.25s ease, opacity 0.25s ease, visibility 0.25s ease, margin 0.25s ease;

        @media only screen and (max-width: $bp-lg) {
            font-size: 14px;
        }

        @media only screen and (max-width: $bp-md) {
            font-size: 12px;
        }
    }

    // &--badge &__field{
    //     border-radius: 50px;

    //     &::placeholder{
    //         color: inherit;
    //     }
    // }

    // sm

    &--sm{
        font-size: 14px;
    }


    &--sm &__icon {
        top: 7px;
        left: 6px;
        width: 16px;
        height: 16px;

        &--right{
            left: auto;
            right: 6px;
        }

        &--right + &--right{
            right: 48px;

            @media only screen and (max-width: $bp-lg) {
                right: 36px;
            }

            @media only screen and (max-width: $bp-md) {
                right: 22px;
            }
        }
    }

    &--sm &__icon:not(.input__icon--right) ~ &__field {
        padding-left: calc(6px + 16px + 4px);
    }

    &--sm &__icon:not(.input__icon--right) + &__icon:not(.input__icon--right) ~ &__field {
        padding-left: calc(6px + 16px + 6px + 16px + 4px);
    }

    &--sm &__icon--right ~ &__field {
        padding-right: calc(6px + 16px + 4px);
    }

    &--sm &__icon--right + &__icon--right ~ &__field {
        padding-right: calc(6px + 16px + 6px + 16px + 4px);
    }

    &--sm &__field{
        height: 30px;
        padding: 0 10px;
        font-size: 14px;
        border-radius: 5px;
    }

    // Textarea

    &--textarea{
        max-width: none;
    }

    &--textarea &__field{

    }


    // Actions

    &.is-error &__error{
        display: flex;
        animation: fadeInTop 0.25s ease forwards;
    }

    &.is-filled &__field,
	&.is-focus &__field {
        border-color: $blue;
    }


    // White

    &--white {
        color: #fff;
        fill: #fff;
    }

    &--white &__field{
        border-color: #80A8C7;

        &::placeholder{
            color: #80A8C7;
        }

        &:hover{
            border-color: $blue-white;
        }
    }

    &--white.is-error &__field {
        border-color: $red;

        &:hover:not([disabled]) {
            border-color: #CF1322;
        }
    }

    &--white.is-filled &__field{
        border-color: $blue-white;
    }

	&--white.is-focus &__field{
		border-color: $blue-white;
	}


    &.is-error{
        color: $red;
        fill: $red;
    }

    &.is-error &__field {
        border-color: $red;

        &::placeholder{
            color: $red;
        }

        &:hover:not([disabled]) {
            border-color: #CF1322;
        }
    }

}
