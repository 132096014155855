@import "@theme/vars.scss";

.c-service {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 2px solid $blue-white;
    border-radius: 15px;
    padding: 38px;
    background-color: $blue-dark;
    color: #fff;

    @media only screen and (max-width: $bp-xl) {
        padding: 30px;
    }

    @media only screen and (max-width: $bp-lg) {
        border-width: 1px;
        border-radius: 10px;
        padding: 18px;
    }

    &__header{
        margin-bottom: 20px;

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 14px;
        }
    }

    &__title {
        margin-bottom: 5px;

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 3px;
        }

        @media only screen and (max-width: $bp-md) {
            font-size: 18px;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }

    &__subtitle {
        font-size: 20px;
        line-height: 145%;

        @media only screen and (max-width: $bp-lg) {
            font-size: 18px;
        }

        @media only screen and (max-width: $bp-md) {
            font-size: 14px;
        }
    }

    &__body{
        margin-bottom: 20px;

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 15px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        max-height: 130px;
        transition: max-height 0.35s ease;

        @media only screen and (max-width: $bp-lg) {
            max-height: 96px;
        }
    }

    &__list-content {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;

        p{
            font-weight: 300;
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 10px;

            @media only screen and (max-width: $bp-lg) {
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 6px;
            }

            @media only screen and (max-width: $bp-md) {
                font-size: 14px;
            }

            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    &__list-more {
        display: none;
        font-size: 18px;
        line-height: 145%;
        font-weight: 700;
        color: #fff;
        width: 100%;
        margin-top: 10px;
        transition: color 0.25s ease;

        &:hover{
            color: $blue-medium;
        }

        @media only screen and (max-width: $bp-lg) {
            margin-top: 6px;
            font-size: 16px;
        }

        @media only screen and (max-width: $bp-md) {
            font-size: 14px;
        }
        
        @media only screen and (max-width: $bp-sm) {
            font-size: 12px;
        }

        span{
            animation: fadeIn 0.5s ease forwards;
            
            &:last-child{
                display: none;
            }
        }

        &.is-visible{
            display: flex;
        }

        &.is-swapped {
            span{
                &:first-child{
                    display: none;
                }

                &:last-child{
                    display: block;
                }
            }
        }
    }

    &__footer{
        margin-top: auto;
    }

    &__price {
        font-size: 35px;
        line-height: 145%;
        margin-bottom: 20px;

        @media only screen and (max-width: $bp-xl) {
            font-size: 30px;
        }

        @media only screen and (max-width: $bp-lg) {
            font-size: 26px;
            line-height: 135%;
            margin-bottom: 14px;
        }

        @media only screen and (max-width: $bp-md) {
            font-size: 20px;
        }
    }

    &__button {
        width: 100%;
    }
}
